import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import ChatBotCreatorScreen from "./components/chatbotCreaterComponents/ChatBotCreatorScreen";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/dashboard" element={<ChatBotCreatorScreen />} />
        </Routes>
      </BrowserRouter>
    </Provider>
);

reportWebVitals();
