import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateLabelThunk, getGetOrdersBySkuThunk, getPendingOrdersForPackagingThunk, markOrderAsCompletedThunk } from "../store/chatBotCreatorThunk";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Card, CardContent, TextField } from "@mui/material";
import useScanDetection from "use-scan-detection";
import DownloadIcon from "@mui/icons-material/Download";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const options = {
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

function PendingOrderItemsCard({ itemDetails, key, setSku, sku, lableClick, setLabelClick }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const onSelectSku = () => {
    dispatch(getGetOrdersBySkuThunk({ sku: itemDetails?.items[0]?.sku, JWT: state.loginToken }));
    setSku(itemDetails?.items[0]?.sku);
    setLabelClick(false);
  };

  return (
    <div key={key} className={sku === itemDetails?.items[0]?.sku ? "ai-bot-action-item action-item-view-selected d-flex" : "ai-bot-action-item d-flex"} onClick={onSelectSku}>
      <img src={itemDetails?.designDetails[0]?.image[0]} height={70} width={70} />

      <div className="hover-pointer ms-2" style={{ cursor: "pointer", width: "100%" }}>
        <div>{itemDetails.items[0].name}</div>
        <div className="small-font">{itemDetails.items[0].sku}</div>
      </div>
    </div>
  );
}

function OrderList({ orderData, key, setSku, lableClick, setLabelClick, sku }) {
  const state = useSelector((state) => state.chatBotCreatorStore);
  const dispatch = useDispatch();

  const onClickMarkAsCompleted = () => {
    dispatch(markOrderAsCompletedThunk({ orderId: orderData.orderId, JWT: state.loginToken }));
    setSku("");
  };

  const onClickDownloadLabel = () => {
    setLabelClick(true);
    dispatch(generateLabelThunk({ awb: orderData.awb, JWT: state.loginToken }));
  };

  return (
    <Card key={key} className="card-border mb-3 mb-3" fullWidth>
      <CardContent sx={{ padding: ".8rem !important" }}>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-2">
                <b className="text-primary small-font">Order Id</b>
                <div className="mb-2">{orderData.orderId}</div>
                <b className="text-primary small-font">AWB</b>
                <br />
                <b className="small-font">{orderData.awb}</b>
              </div>
              <div className="col-7">
                <b className="text-primary small-font">Items:</b>
                {orderData?.items?.map((item, index) => (
                  <div className="mb-3" key={index}>
                    <div className="row">
                      <div className="col-2">
                        <img src={orderData?.designDetails?.find((designItem) => designItem.productName === item.itemName?.split(" -")[0])?.image[0]} height={50} width={50} />
                        {/* <img src={orderData?.designDetails[0]?.image[0]} height={50} width={50} /> */}
                      </div>
                      <div className="col-10">
                        <label className="col-12 small-font">{item.itemName}</label>
                        <label className="col-6 mt-2">{item.sku}</label>
                        <label className="col-6 mt-2 h6" > <b>  {item.quantity} pieces </b></label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-3">
                {state.showMarkOrderAsCompletedLoader ? (
                  <Button className="mt-4" variant="outlined" size="small" fullWidth>
                    Completing Order...
                  </Button>
                ) : (
                  <Button className="" variant="outlined" size="small" startIcon={<SaveIcon />} onClick={onClickMarkAsCompleted} fullWidth>
                    Mark as Completed
                  </Button>
                )}

                {/* {lableClick && <object data={state.generatedLabel} type="image/jpg" width="200px" height="200px" border="1"></object>}
                  {lableClick && <a href={state.generatedLabel} target="_blank" rel="noopener noreferrer" />} */}
              </div>
            </div>
            {state.userRole === "admin" && (
              <div className="row mt-2">
                <b className="text-primary col-2 small-font">Packager:</b>
                <div className="col-9">
                  {orderData.assignedPackager?.firstname} {orderData.assignedPackager?.lastname}, {orderData.assignedPackager?.username}, {orderData.assignedPackager?.phone}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mx-5">
          <Button variant="contained" href="" className="mt-3 p-2" size="small" startIcon={<DownloadIcon />} onClick={onClickDownloadLabel}>
            Label
          </Button>
          {
            // lableClick && (
            //   <>
            //     {/* <Document conte options={options} externalLinkTarget="_blank" externalLinkRel="_blank" file={state.generatedLabel} onContextMenu={(e) => e.preventDefault()}>
            //       <Page pageNumber={1} />
            //     </Document> */}
            //   </>
            // )
          }
        </div>
        <p className="text-danger centered">{state.markOrderAsCompletedErrorMessage}</p>
      </CardContent>
    </Card>
  );
}

function NewPackageComponent() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);
  const [searchedProductName, setSearchProductName] = useState("");
  const [designListArray, setDesignListArray] = useState([]);
  const [sku, setSku] = useState("");
  const [lableClick, setLabelClick] = useState(false);

  const [value, setValue] = useState("");
  const [count, setCount] = useState(0);

  useScanDetection({
    onComplete: (code) => {
      console.log(code);
      setValue(code);
    },
  });

  const onSearchProduct = (e) => {
    setSku("");

    if (e.target.value === "") {
      setSearchProductName("");
      setDesignListArray(state.pendingOrderItemsList);
      return;
    }
    setSearchProductName(e.target.value);
    const newArray = designListArray.filter((element) => element?.items[0]?.sku?.toLowerCase().includes(e.target.value.toLowerCase()));
    setDesignListArray(newArray);
  };

  useEffect(() => {
    dispatch(getPendingOrdersForPackagingThunk({ JWT: state.loginToken }));
  }, []);

  useEffect(() => {
    setDesignListArray(state.pendingOrderItemsList);
  }, [state.pendingOrderItemsList]);
  
  return (
    <div style={{ height: "100%" }}>
      <div className="row pb-3" style={{ height: "7%" }}>
        <div className="col-10">
          <TextField label={"Search By SKU"} size="small" name="Details" value={searchedProductName} onChange={onSearchProduct} fullWidth></TextField>
        </div>
        <div className="col-2">
          <label className="font-large mt-2">Total orders :</label>
          <b> {state.pendingOrderTotal}</b>
        </div>
      </div>
      {state.showGetPendingOrdersForPackagingLoader ? (
        <div className="centered" style={{ height: "300px" }}>
          <div className="spinner-border mx-2"></div>
          <span>Loading Order Ids...</span>
        </div>
      ) : (
        <div className="row mt-3" style={{ height: "93%" }}>
          <div className="col-12" style={{ height: "100%" }}>
            {state.pendingOrderItemsList.length === 0 ? (
              <h6>No Orders Found.</h6>
            ) : (
              <div style={{ height: "100%" }}>
                <div className="row " style={{ height: "100%" }}>
                  <div className="col-5" style={{ height: "100%", overflowY: "scroll" }}>
                    {state.getPendingOrdersForPackagingErrorMessage === "" ? (
                      designListArray?.map((itemDetails, index) => <PendingOrderItemsCard itemDetails={itemDetails} key={index} setSku={setSku} sku={sku} lableClick={lableClick} setLabelClick={setLabelClick} />)
                    ) : (
                      <p className="text-danger mt-1">{state.getPendingOrdersForPackagingErrorMessage}</p>
                    )}
                  </div>

                  <div className="col-7" style={{ height: "100%", overflowY: "scroll" }}>
                    {sku !== "" && state.orderListBySku?.map((orderData, key) => <OrderList orderData={orderData} key={key} setSku={setSku} lableClick={lableClick} setLabelClick={setLabelClick} sku={sku} />)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NewPackageComponent;
