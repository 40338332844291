import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_SERVER_URL } = process.env;

export const UserLoginThunk = createAsyncThunk("login-user", async ({ username, password }) => {
  try {
    const response = await axios.post(`${REACT_APP_SERVER_URL}/user/auth/v1/login`, { username, password });
    return response?.data?.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const createNewDesignThunk = createAsyncThunk("create-new-design", async ({ formData, JWT }) => {
  try {
    const response = await axios.post(`${REACT_APP_SERVER_URL}/design/v1/create`, formData, {
      headers: {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const editDesignThunk = createAsyncThunk("edit-design", async ({ formData, JWT }) => {
  try {
    const response = await axios.post(`${REACT_APP_SERVER_URL}/design/v1/update`, formData, {
      headers: {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const deleteDesignThunk = createAsyncThunk("delete-design", async ({ productId, JWT }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/design/v1/delete`,
      { productId },
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getAllDesignsThunk = createAsyncThunk("get-all-designs", async ({ JWT, currentPage, pageSize, searchedProductName }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/design/v1/list`, {
      params: {
        currentPage,
        searchedProductName,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getAllDesignsNamesThunk = createAsyncThunk("get-all-designs-name", async ({ JWT }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/design/v1/get-all-designs-name`, {
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const createNewInventoryThunk = createAsyncThunk("add-inventory-by-scanning", async ({ inventoryArray, JWT }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/inventory/v1/add-inventory-by-scanning`,
      { inventoryArray },
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getAllInventoryThunk = createAsyncThunk("get-all-inventory", async ({ JWT, currentPage, pageSize, searchedProductName }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/inventory/v1/list`, {
      params: {
        currentPage,
        pageSize,
        searchedProductName,
      },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getInventoryDetailsByProductIdThunk = createAsyncThunk("get-inventory-details-by-productId", async ({ productId, JWT }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/inventory/v1/get-inventory-details-by-productId`, {
      params: { productId },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const createNewUserThunk = createAsyncThunk("create-new-user", async ({ formData, JWT }) => {
  try {
    const response = await axios.post(`${REACT_APP_SERVER_URL}/user/v1/create`, formData, {
      headers: {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const editUserThunk = createAsyncThunk("edit-user", async ({ formData, JWT }) => {
  try {
    const response = await axios.post(`${REACT_APP_SERVER_URL}/user/v1/update`, formData, {
      headers: {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const deleteUserThunk = createAsyncThunk("delete-user", async ({ userId, JWT }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/user/v1/delete`,
      { userId },
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});
//----------------------------------------------------------------------
export const assignedEmployeeThunk = createAsyncThunk("assign-employee", async ({ userId, orderId, JWT }) => {
  try {
    console.log(" thunk console  ", userId);
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/order/v1/assign-employee`,
      { userId, orderId },
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

//----------------------------------------------------------------------
export const assignPackagerThunk = createAsyncThunk("assign-packager", async ({ userId, orderId, JWT }) => {
  try {
    console.log(" thunk console  ", userId);
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/order/v1/assign-packager`,
      { userId, orderId },
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getAllUsersThunk = createAsyncThunk("get-all-users", async ({ JWT, currentPage, pageSize }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/user/v1/list`, {
      params: {
        currentPage,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const createNewOrderThunk = createAsyncThunk("create-new-order", async ({ customerName, customerPhone, customerAddress, itemCollection, JWT }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/order/v1/create`,
      { customerName, customerPhone, customerAddress, itemCollection },
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getAllPendingOrdersThunk = createAsyncThunk("get-all-pending-orders", async ({ JWT, currentPage, pageSize, searchedPhoneNumber, searchedOrderID }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/order/v1/get-all-pending-orders`, {
      params: {
        currentPage,
        searchedPhoneNumber,
        searchedOrderID,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getAllCompletedOrdersThunk = createAsyncThunk("get-all-completed-orders", async ({ JWT, currentPage, searchedOrderID, searchedPhoneNumber, pageSize }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/order/v1/get-all-completed-orders`, {
      params: {
        currentPage,
        searchedPhoneNumber,
        searchedOrderID,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const addNewRackThunk = createAsyncThunk("add-new-rack", async ({ floorNumber, rackNumber, JWT }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/rack/v1/create`,
      { floorNumber, rackNumber },
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const updateRackThunk = createAsyncThunk("update-rack", async ({ rackId, floorNumber, rackNumber, JWT }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/rack/v1/update`,
      { rackId, floorNumber, rackNumber },
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getAllRacksByFloorThunk = createAsyncThunk("get-all-racks-by-floor", async ({ JWT }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/rack/v1/list-by-floor`, {
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getAllRacksThunk = createAsyncThunk("get-all-racks", async ({ JWT }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/rack/v1/list`, {
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const addRacksToDesignThunk = createAsyncThunk("add-racks-to-design", async ({ variantId, rackCollection, JWT }) => {
  try {
    console.log(variantId, "last");
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/design/v1/add-racks`,
      { variantId, rackCollection },
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getDesignsByFloorAndRackThunk = createAsyncThunk("get-designs-by-floor-and-rack", async ({ floorNumber, rackNumber, JWT }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/design/v1/get-designs-by-floor-and-rack`, {
      params: {
        floorNumber,
        rackNumber,
      },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getOrderIdListWithItemsThunk = createAsyncThunk("get-orderId-list-with-items", async ({ JWT }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/order/v1/get-orderId-list-with-items`, {
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const markOrderAsCompletedThunk = createAsyncThunk("mark-order-as-completed", async ({ orderId, JWT }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/order/v1/mark-order-as-completed`,
      {
        orderId,
      },
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getPendingOrdersForPackagingThunk = createAsyncThunk("get-sku-of-pending-orders", async ({ JWT }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/package/v1/get-sku-of-pending-orders`, {
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getGetOrdersBySkuThunk = createAsyncThunk("get-orders-by-sku", async ({ sku, JWT }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/package/v1/get-orders-by-sku`, {
      params: { sku },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const generateLabelThunk = createAsyncThunk("generate-label", async ({ awb, JWT }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/generate-invoice`,
      {
        awb,
      },
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getOrderDeliverdThunk = createAsyncThunk("get-orders-deliverd-by-date", async ({ JWT, startDate, endDate }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/order/v1/get-orders-deliverd-by-date`, {
      params: { startDate, endDate },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});

export const getAllCustomeOrdersThunk = createAsyncThunk("get-all-custom-orders", async ({ JWT, startDate, endDate }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_SERVER_URL}/get-all-custom-orders`,
      { from: startDate, to: endDate },
      {
        headers: {
          Authorization: `Bearer ${JWT}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});


export const getPendingOrdersByEmployeeThunk = createAsyncThunk("get-pending-orders-by-employee", async ({ JWT, currentPage, pageSize, searchedPhoneNumber, searchedOrderID }) => {
  try {
    const response = await axios.get(`${REACT_APP_SERVER_URL}/order/v1/get-pending-orders-by-employee`, {
      params: {
        currentPage,
        searchedPhoneNumber,
        searchedOrderID,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw Error("Server Not Reachable");
    }
  }
});