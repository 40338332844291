import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompletedOrdersThunk } from "../store/chatBotCreatorThunk";
import { Modal } from "react-bootstrap";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { Card, CardContent, Pagination, TextField } from "@mui/material";
import _debounce from "lodash/debounce";

function OrderCard({ order, index, onClickViewItems }) {
  return (
    <Card className="card-border mb-3" fullWidth>
      <CardContent sx={{ padding: ".8rem !important" }}>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-2">
                <div className="small-font">Order Id</div>
                <b className="text-primary">{order.orderId}</b>
                <br />
                <label className="small-font">{moment(order.createdDateTime).format("DD MMM, YYYY")}</label> <br />
                <label className="small-font">{moment(order.createdDateTime).format("hh:mm a")}</label>
              </div>

              <div className="col-1">
                <div>
                  <b className="text-primary  small-font"> Rs {order.totalPrice}</b>
                  <br></br>
                </div>
                <div className="small-font">Items:</div>
                <IconButton variant="text" onClick={() => onClickViewItems(order.items, order.skuDetails, order.customerName)}>
                  <FormatListNumberedIcon color="primary" />
                </IconButton>
              </div>

              <div className="col-3">
                <div className="small-font">Customer:</div>
                <b>{order.customerName}</b>
                <div className="small-font">
                  {order.customerAddress?.address1}, {order.customerAddress?.address2}, {order.customerAddress?.city}, {order.customerAddress?.state},{order.customerAddress?.country},
                  {order.customerAddress?.zipcode},
                  <div>
                    phone : <b>{order?.customerPhone} </b>
                  </div>
                </div>
              </div>

              <div className="col-2">
                <b className="text-success">{order?.channelName ? order?.channelName : "SHOPIFY"}</b>&nbsp;
                <br />
                <label className="small-font">
                  <b>awb:</b> {order?.awb ? order?.awb : "not assigned"}
                </label>
                <br />
                <label className="small-font">{order?.courierName ? order?.courierName : ""}</label>
              </div>

              <div className="col-2">
                <div className="small-font">Assigned To Employee:</div>
                <b>
                  {order.assignedEmployee?.firstname} {order.assignedEmployee?.lastname}
                </b>
                <div className="small-font"> {order.assignedEmployee?.username}</div>
                <div className="small-font"> {order.assignedEmployee?.phone} </div>
                <br />
              </div>
              <div className="col-2">
                <div className="small-font">Assigned To Packager:</div>
                <b>
                  {order.assignedPackager?.firstname} {order.assignedPackager?.lastname}
                </b>
                <div className="small-font"> {order.assignedPackager?.username}</div>
                <div className="small-font"> {order.assignedPackager?.phone} </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function CompletedOrdersComponents() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const [showAddressModel, setShowAddressModel] = useState(false);
  const [showItemsModel, setShowItemsModel] = useState(false);
  const [customerAddress, setCustomerAddress] = useState("");
  const [itemCollection, setItemCollection] = useState([]);
  const [skuDetails, setSkuDetails] = useState([]);
  const [customerName, setCustomerName] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedPhoneNumber, setSearchedPhoneNumber] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchedOrderID, setSearchedOrderID] = useState("");

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const onClickViewItems = (items, skuDetails, customerName) => {
    setCustomerName(customerName);
    setItemCollection(items);
    setSkuDetails(skuDetails);
    setShowItemsModel(true);
  };

  const getAllCompletedOrders = async () => {
    dispatch(getAllCompletedOrdersThunk({ JWT: state.loginToken, currentPage, searchedPhoneNumber, searchedOrderID, pageSize: 10 }));
  };

  const debouncedSearch = _debounce((value) => {
    setDebouncedValue(value);
  }, 2000);
  useEffect(() => {
    getAllCompletedOrders();

    debouncedSearch(searchedPhoneNumber);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchedPhoneNumber, currentPage]);

  useEffect(() => {
    getAllCompletedOrders();

    debouncedSearch(searchedOrderID);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchedOrderID, currentPage]);

  console.log("itemCollection", itemCollection);

  return (
    <>
      <div className="row mb-2">
        <div className="col-6">
          <div>
            <TextField label={"Search By Order ID"} size="small" name="Details" value={searchedOrderID} onChange={(e) => setSearchedOrderID(e.target.value)} fullWidth></TextField>
          </div>
        </div>
        <div className="col-6">
          <div>
            <TextField label={"Search By Phone Number"} size="small" name="Details" value={searchedPhoneNumber} onChange={(e) => setSearchedPhoneNumber(e.target.value)} fullWidth></TextField>
          </div>
        </div>
      </div>
      {state.getAllCompletedOrdersErrorMessage === "" ? (
        <div className="col-12" style={{ height: "94%", overflowY: "scroll" }}>
          {state.showGetAllCompletedOrdersLoader ? (
            <div className="centered" style={{ height: "300px" }}>
              <div className="spinner-border mx-2"></div>
              <span>Loading Order List...</span>
            </div>
          ) : state.orderList?.length === 0 ? (
            <h6>No Completed Orders Found.</h6>
          ) : (
            state.orderList?.map((order, index) => <OrderCard order={order} index={index} onClickViewItems={onClickViewItems} />)
          )}
          {/* -----------------------------  Address Model ------------------------------ */}
          <Modal size="lg" show={showAddressModel} onHide={() => setShowAddressModel(false)}>
            <Modal.Header className="modal-bg">
              <Modal.Title className="px-2">{customerName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row mb-2">
                <div className="col-12">
                  <b>Address Line 1:</b> {customerAddress.address1}
                </div>
              </div>
              {customerAddress.address2 && (
                <div className="row mb-2">
                  <div className="col-12">
                    <b>Address Line 2:</b> {customerAddress.address2}
                  </div>
                </div>
              )}
              <div className="row mb-2">
                <div className="col-6">
                  <b>City:</b> {customerAddress.city}
                </div>
                <div className="col-6">
                  <b>State:</b> {customerAddress.state}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-6">
                  <b>Country:</b> {customerAddress.country}
                </div>
                <div className="col-6">
                  <b>Zipcode:</b> {customerAddress.zipcode}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* -----------------------------  Address Model ------------------------------ */}

          {/* -----------------------------  Items Model ------------------------------ */}

          <Modal size="xl" show={showItemsModel} onHide={() => setShowItemsModel(false)}>
            <Modal.Header className="modal-bg">
              <div className="d-flex justify-content-between">
                <Modal.Title className="px-2">Order from {customerName}</Modal.Title>
              </div>
            </Modal.Header>
            <Modal.Body>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="col-1">Sr.</th>
                    <th className="col-2">Product Name</th>
                    <th className="col-2">Design</th>
                    <th className="col-2">SKU</th>
                    <th className="col-1">Type</th>
                    <th className="col-1 icon">Price/Item</th>
                    <th className="col-1 icon">Quantity</th>
                    <th className="col-2 icon">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {itemCollection?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="col-1 icon">{item.itemName}</td>
                      <td>
                        <img
                          src={skuDetails?.find((designData) => item?.itemName?.split(" -")[0] === designData.productName)?.image[0]}
                          alt=""
                          height={130}
                          width={130}
                          style={{ objectFit: "cover" }}
                        />
                      </td>
                      <td className="col-1 icon">{item.sku}</td>
                      <td className="col-1 icon">{skuDetails?.find((designData) => item?.itemName?.split(" -")[0] === designData.productName)?.type}</td>
                      <td className="col-1 icon">{item.price}</td>
                      <td className="col-1 icon">{item.quantity}</td>
                      <td className="col-1 icon">
                        {item.rackCollection.length > 0 ? (
                          item.rackCollection?.map((ele, key) => (
                            <>
                              <td className="col-1 icon">
                                {ele.floorNumber}:{ele.rackNumber}
                              </td>
                              <br />
                            </>
                          ))
                        ) : (
                          <p>No Racks Alloted</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>
          {/* -----------------------------  Items Model ------------------------------ */}
        </div>
      ) : (
        <p className="text-danger centered">{state.getAllCompletedOrdersErrorMessage}</p>
      )}

      {state.orderList?.length > 0 && (
        <div className="mt-3 d-flex justify-content-center" style={{ position: "fixed", right: 10, bottom: 2 }}>
          <Pagination
            count={Math.ceil(state.totalOrderCount / 10) > 10 ? Math.ceil(state?.totalOrderCount / 10) : Math.ceil(state.totalOrderCount / 10)}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}

export default CompletedOrdersComponents;
