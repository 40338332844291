import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignPackagerThunk, assignedEmployeeThunk, getAllPendingOrdersThunk, getAllUsersThunk, getPendingOrdersByEmployeeThunk } from "../store/chatBotCreatorThunk";
import { Modal } from "react-bootstrap";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import _debounce from "lodash/debounce";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { Card, Button, CardContent, MenuItem, Pagination, Select, TextField } from "@mui/material";
import { closeAssignEmployeeModel, closeAssignPackagerModel, showAssignEmployeeModel, showAssignPackagerModel } from "../store/chatBotCreatorSlice";

function OrderCard({ order, index, onClickViewItems, setOrderData, setEmployeeDetails, setPackagerDetails }) {
  const [selectedEmplyoee, setSelectedEmplyoee] = useState("Select Employee");
  const [selectedPackager, setSelectedPackager] = useState("select Packager");

  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const onChangeEmployee = (e) => {
    setOrderData(order);
    setEmployeeDetails(e.target.value);
    setSelectedEmplyoee(e.target.value);
    dispatch(showAssignEmployeeModel());
  };
  const onchangePackager = (e) => {
    setOrderData(order);
    setSelectedPackager(e.target.value);
    setPackagerDetails(e.target.value);
    dispatch(showAssignPackagerModel());
  };
  
  return (
    <Card
      key={index}
      className="card-border mb-3"
      fullWidth
      style={{
        borderRadius: "16px",
        padding: "1rem",
      }}
    >
      <CardContent sx={{ padding: ".8rem !important" }}>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-2">
                <div className="small-font">Order Id</div>
                <b className="text-primary">{order.orderId}</b>
                <br />
                <label className="small-font">{moment(order.createdDateTime).format("lll")}</label> <br />
                <div className="mt-3 font-weight-bold">
                  {order.items?.map((item, index) => (
                    <div key={index}>
                      {order.variantsDetails[index]?.inventory < item?.quantity ? (
                        <div className="text-danger">
                          <b>Out of stock</b>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ))}
                  {/* {order.items?.length === order.variantsDetails?.length ? (
                    <div>
                      {order.items?.map((item, index) => (
                        <div key={index}>
                          {order.variantsDetails[index]?.inventory < item?.quantity ? (
                            <div className="text-danger">
                              <b>Out of stock</b>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {order.items?.map((item, index) => (
                        <div key={index}>
                          {order.variantsDetails[index]?.inventory < item?.quantity ? (
                            <div className="text-danger">
                              <b>Out of stock</b>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ))}
                    </div>
                  )} */}
                </div>
              </div>

              <div className="col-1">
                <div className="small-font">Items:</div>
                <IconButton variant="text" onClick={() => onClickViewItems(order.items, order.skuDetails, order.customerName)}>
                  <FormatListNumberedIcon color="primary" />
                </IconButton>
              </div>

              <div className="col-3">
                <div className="small-font">Customer:</div>
                <b>{order.customerName}</b>
                <div className="small-font">
                  {order.customerAddress?.address1}, {order.customerAddress?.address2}, {order.customerAddress?.city}, {order.customerAddress?.state}, {order.customerAddress?.country},
                  {order.customerAddress?.zipcode}
                  <>
                    <div className="small-font">
                      Phone:<b> {order?.customerPhone}</b>
                    </div>
                  </>
                </div>
              </div>

              <div className="col-2">
                <b className="text-success">{order?.channelName ? order?.channelName : "SHOPIFY"}</b>&nbsp;
                <br />
                <label className="small-font">
                  <b>awb:</b> {order?.awb ? order?.awb : "not assigned"}
                </label>
                <br />
                <label className="small-font">{order?.courierName ? order?.courierName : ""}</label>
              </div>

              <div className="col-2">
                <div className="small-font">Emplyoee </div>
                {order.assignedEmployee === null ? (
                  <Select key={index} labelId="select-input" size="small" fullWidth label="employee" id={`select-employee ${index}`} onChange={(e) => onChangeEmployee(e)} value={selectedEmplyoee}>
                    <MenuItem id="index" value={"Select Employee"}>
                      Select Employee
                    </MenuItem>
                    {state.userList.length > 0 ? (
                      state.userList.map((userData, index) =>
                        userData.role === "employee" ? (
                          <MenuItem key={index} value={userData._id}>
                            {userData.firstname} {userData.lastname}
                          </MenuItem>
                        ) : null
                      )
                    ) : (
                      <div>No Users Found. Please Add Users.</div>
                    )}
                  </Select>
                ) : (
                  <>
                    <b>
                      {order.assignedEmployee?.firstname} {order.assignedEmployee?.lastname}
                    </b>
                    <div className="small-font"> {order.assignedEmployee?.username}</div>
                    <div className="small-font"> {order.assignedEmployee?.phone} </div>
                  </>
                )}

                <br />
              </div>
              <div className="col-2">
                <div className="small-font">Packager </div>
                {order.assignedPackager === null ? (
                  <Select labelId="select-input" size="small" fullWidth label="packager" value={selectedPackager} onChange={onchangePackager}>
                    <MenuItem value={"select Packager"}>Select Packager</MenuItem>
                    {state.userList.length > 0 ? (
                      state.userList.map((userData, index) =>
                        userData.role === "packager" ? (
                          <MenuItem key={index} value={userData._id}>
                            {userData.firstname} {userData.lastname}
                          </MenuItem>
                        ) : null
                      )
                    ) : (
                      <div>No Users Found. Please Add Users.</div>
                    )}
                  </Select>
                ) : (
                  <>
                    <b>
                      {order.assignedPackager?.firstname} {order.assignedPackager?.lastname}
                    </b>
                    <div className="small-font"> {order.assignedPackager?.username}</div>
                    <div className="small-font"> {order.assignedPackager?.phone} </div>
                  </>
                )}

                <br />
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function PendingByEmployeeComponent() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);
  const [showAddressModel, setShowAddressModel] = useState(false);
  const [showItemsModel, setShowItemsModel] = useState(false);
  const [customerAddress, setCustomerAddress] = useState("");
  const [itemCollection, setItemCollection] = useState([]);
  const [skuDetails, setSkuDetails] = useState([]);
  const [customerName, setCustomerName] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeDetails, setEmployeeDetails] = useState("");
  const [packagerDetails, setPackagerDetails] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchedOrderID, setSearchedOrderID] = useState("");
  const [searchedPhoneNumber, setSearchedPhoneNumber] = useState("");

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const onClickAssignPackager = () => {
    dispatch(
      assignPackagerThunk({
        userId: packagerDetails,
        orderId: orderData._id,
        JWT: state.loginToken,
      })
    );
    setTimeout(() => {
      dispatch(
        getAllPendingOrdersThunk({
          JWT: state.loginToken,
          currentPage,
          pageSize: 10,
          searchedPhoneNumber,
          searchedOrderID,
        })
      );
    }, 200);
  };

  const onClickAssignEmployee = () => {
    dispatch(
      assignedEmployeeThunk({
        userId: employeeDetails,
        orderId: orderData._id,
        JWT: state.loginToken,
      })
    );
    setTimeout(() => {
      dispatch(
        getAllPendingOrdersThunk({
          JWT: state.loginToken,
          currentPage,
          pageSize: 10,
          searchedPhoneNumber,
          searchedOrderID,
        })
      );
    }, 200);
  };

  const onClickViewItems = (items, skuDetails, customerName) => {
    setCustomerName(customerName);
    setItemCollection(items);
    setSkuDetails(skuDetails);
    setShowItemsModel(true);
  };

  const getAllPendingOrders = async () => {
    dispatch(
      getPendingOrdersByEmployeeThunk({
        JWT: state.loginToken,
        searchedOrderID,
        searchedPhoneNumber,
        currentPage,
        pageSize: 10,
      })
    );
  };

  const debouncedSearch = _debounce((value) => {
    setDebouncedValue(value);
  }, 2000);

  useEffect(() => {
    dispatch(getAllUsersThunk({ JWT: state.loginToken }));
    getAllPendingOrders();
    debouncedSearch(searchedPhoneNumber);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchedPhoneNumber, currentPage]);

  useEffect(() => {
    dispatch(getAllUsersThunk({ JWT: state.loginToken }));
    getAllPendingOrders();
    debouncedSearch(searchedOrderID);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchedOrderID, currentPage]);

  return (
    <div style={{ height: "92%" }}>
      <div>
        <div className="row mb-2">
          <div className="col-6">
            <div>
              <TextField label={"Search By Order ID"} size="small" name="Details" value={searchedOrderID} onChange={(e) => setSearchedOrderID(e.target.value)} fullWidth></TextField>
            </div>
          </div>
          <div className="col-6">
            <div>
              <TextField label={"Search By Phone Number"} size="small" name="Details" value={searchedPhoneNumber} onChange={(e) => setSearchedPhoneNumber(e.target.value)} fullWidth></TextField>
            </div>
          </div>
        </div>
      </div>
      {state.getAllPendingOrdersByEmployeeErrorMessage === "" ? (
        <div className="col-12" style={{ height: "100%", overflowY: "scroll" }}>
          {state.showGetAllPendingOrdersByEmployeeLoader ? (
            <div className="centered" style={{ height: "300px" }}>
              <div className="spinner-border mx-2"></div>
              <span>Loading Order List...</span>
            </div>
          ) : state.orderList1?.length === 0 ? (
            <h6>No Pending Orders Found.</h6>
          ) : (
            state.orderList1?.map((order, index) => (
              <OrderCard order={order} index={index} setOrderData={setOrderData} onClickViewItems={onClickViewItems} setPackagerDetails={setPackagerDetails} setEmployeeDetails={setEmployeeDetails} />
            ))
          )}
          {/* --------------------------------------- Assign Model ----------------------------------------------- */}
          <Modal size="lg" show={state.showAssignEmployeeModel} onHide={() => dispatch(closeAssignEmployeeModel())}>
            <Modal.Header className="modal-bg">
              <Modal.Title className="px-2">Assign Employee </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Do you want to Assign to Employee ?
              <div className="d-flex justify-content-end mt-3">
                {state.showAssignEmployeeLoader ? (
                  <Button className="me-2" variant="contained" color="error">
                    Assigning...
                  </Button>
                ) : employeeDetails === "Select Employee" ? (
                  <div></div>
                ) : (
                  <Button className="me-3" variant="contained" color="success" onClick={onClickAssignEmployee}>
                    Assign Employee
                  </Button>
                )}
                <Button variant="contained" color="primary" onClick={() => dispatch(closeAssignEmployeeModel())}>
                  Cancel
                </Button>
              </div>
              {state.assignEmployeeErrorMessage !== "" && <p className="text-danger centered mt-3">{state.assignEmployeeErrorMessage}</p>}
            </Modal.Body>
          </Modal>

          {/* --------------------------------------- packager Model ---------------------------------------------*/}
          <Modal size="lg" show={state.showAssignPackagerModel} onHide={() => dispatch(closeAssignPackagerModel())}>
            <Modal.Header className="modal-bg">
              <Modal.Title className="px-2">Assign Packager </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Do you want to Assign to Packager ?
              <div className="d-flex justify-content-end mt-3">
                {state.showAssignPackagerLoader ? (
                  <Button className="me-2" variant="contained" color="error">
                    Assigning...
                  </Button>
                ) : packagerDetails === "select Packager" ? (
                  <div></div>
                ) : (
                  <Button className="me-3" variant="contained" color="success" onClick={onClickAssignPackager}>
                    Assign Packager
                  </Button>
                )}
                <Button variant="contained" color="primary" onClick={() => dispatch(closeAssignPackagerModel())}>
                  Cancel
                </Button>
              </div>
              {state.assignPackagerErrorMessage !== "" && <p className="text-danger centered mt-3">{state.assignPackagerErrorMessage}</p>}
            </Modal.Body>
          </Modal>
          {/* ------------------------------------------------------------------------------------------------------------------------------- */}

          <Modal size="lg" show={showAddressModel} onHide={() => setShowAddressModel(false)}>
            <Modal.Header className="modal-bg">
              <Modal.Title className="px-2">{customerName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row mb-2">
                <div className="col-12">
                  <b>Address Line 1:</b> {customerAddress.address1}
                </div>
              </div>
              {customerAddress.address2 && (
                <div className="row mb-2">
                  <div className="col-12">
                    <b>Address Line 2:</b> {customerAddress.address2}
                  </div>
                </div>
              )}
              <div className="row mb-2">
                <div className="col-6">
                  <b>City:</b> {customerAddress.city}
                </div>
                <div className="col-6">
                  <b>State:</b> {customerAddress.state}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-6">
                  <b>Country:</b> {customerAddress.country}
                </div>
                <div className="col-6">
                  <b>Zipcode:</b> {customerAddress.zipcode}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* -----------------------------  Address Model ------------------------------ */}

          {/* -----------------------------  Items Model ------------------------------ */}

          <Modal size="xl" show={showItemsModel} onHide={() => setShowItemsModel(false)}>
            <Modal.Header className="modal-bg">
              <div className="d-flex justify-content-between">
                <Modal.Title className="px-2">Order from {customerName}</Modal.Title>
              </div>
            </Modal.Header>
            <Modal.Body>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="col-1">Sr.</th>
                    <th className="col-2">Product Name</th>
                    <th className="col-2">Design</th>
                    <th className="col-2">SKU</th>
                    <th className="col-1">Type</th>
                    <th className="col-1 icon">Price/Item</th>
                    <th className="col-1 icon">Quantity</th>
                    <th className="col-2 icon">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {itemCollection?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="col-1 icon">{item.itemName}</td>
                      <td>
                        <img
                          src={skuDetails?.find((designData) => item?.itemName?.split(" -")[0] === designData.productName)?.image[0]}
                          alt=""
                          height={130}
                          width={130}
                          style={{ objectFit: "cover" }}
                        />
                      </td>
                      <td className="col-1 icon">{item.sku}</td>
                      <td className="col-1 icon">{skuDetails?.find((designData) => item?.itemName?.split(" -")[0] === designData.productName)?.type}</td>
                      <td className="col-1 icon">{item.price}</td>
                      <td className="col-1 icon">{item.quantity}</td>
                      <td className="col-1 icon">
                        {item.rackCollection.length > 0 ? (
                          item.rackCollection?.map((ele, key) => (
                            <>
                              <td className="col-1 icon">
                                {ele.floorNumber}:{ele.rackNumber}
                              </td>
                              <br />
                            </>
                          ))
                        ) : (
                          <p>No Racks Alloted</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>
          {/* -----------------------------  Items Model ------------------------------ */}
        </div>
      ) : (
        <p className="text-danger centered">{state.getAllPendingOrdersByEmployeeErrorMessage}</p>
      )}

      <div className="mt-3 d-flex justify-content-end" style={{ position: "fixed", right: 10, bottom: 2 }}>
        <Pagination
          count={Math.ceil(state?.totalOrderCount1 / 10) > 10 ? Math.ceil(state?.totalOrderCount1 / 10) : Math.ceil(state?.totalOrderCount1 / 10)}
          color="primary"
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default PendingByEmployeeComponent;
