import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewDesignThunk, editDesignThunk } from "../store/chatBotCreatorThunk";
import Joi from "joi";
import SaveIcon from '@mui/icons-material/Save';
import {
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	TextField,
} from "@mui/material";
import { closeEditDesignModal } from "../store/chatBotCreatorSlice";

export function NewDesignComponent({ designData }) {

	const dispatch = useDispatch();
	const state = useSelector((state) => state.chatBotCreatorStore);

	const [productName, setProductName] = useState(designData ? designData.productName : "");
	const [type, setType] = useState(designData ? designData.type : "");
	const [price, setPrice] = useState(designData ? designData.price : 0);
	const [addToShopify, setAddToShopify] = useState(designData ? designData.addedToShopify : false);
	const [sku, setSku] = useState(designData ? designData.sku : "");
	const [details, setDetails] = useState(designData ? designData.details : "");
	const [errors, setErrors] = useState([]);
	const [imageData, setImageData] = useState();
	const [imageUrl, setImageUrl] = useState(designData ? designData?.image : "");
	const [continueSellingWhenOutOfStock, setContinueSellingWhenOutOfStock] =
		useState(designData ? designData.continueSellingWhenOutOfStock : false);

	const clearForm = () => {
		setImageUrl("");
		setSku("");
		setType("");
		setPrice("");
		setProductName("");
		setAddToShopify(false);
		setDetails("");
		setContinueSellingWhenOutOfStock(false);
	};

	const onChangeProductName = (e) => {
		setProductName(e.target.value);
		const index = errors.findIndex((ele) => ele.path[0] === "productName");
		if (index >= 0) {
			errors.splice(index, 1);
			setErrors(errors);
		}
	};

	const onChangeSku = (e) => {
		setSku(e.target.value);
		const index = errors.findIndex((ele) => ele.path[0] === "sku");
		if (index >= 0) {
			errors.splice(index, 1);
			setErrors(errors);
		}
	};

	const onChangeType = (e) => {
		setType(e.target.value);
		const index = errors.findIndex((ele) => ele.path[0] === "type");
		if (index >= 0) {
			errors.splice(index, 1);
			setErrors(errors);
		}
	};

	const onChangePrice = (e) => {
		setPrice(e.target.value);
		const index = errors.findIndex((ele) => ele.path[0] === "price");
		if (index >= 0) {
			errors.splice(index, 1);
			setErrors(errors);
		}
	};

	const onChangeDetails = (e) => {
		setDetails(e.target.value);
		const index = errors.findIndex((ele) => ele.path[0] === "details");
		if (index >= 0) {
			errors.splice(index, 1);
			setErrors(errors);
		}
	};

	const onImageChange = (event) => {
		if (
			event.target.files &&
			event.target.files[0] &&
			event.target.files[0].size < 2097152
		) {
			const index = errors.findIndex((ele) => ele.path[0] === "imageUrl");
			errors.splice(index, 1);
			setErrors(errors);
			const url = URL.createObjectURL(event.target.files[0]);
			setImageUrl(url);
			setImageData(event.target.files[0]);
		} else {
			const index = errors.findIndex((ele) => ele.path[0] === "imageUrl");
			errors.splice(index, 1);
			setErrors(errors);
			setErrors([
				...errors,
				{ message: "Image size cannot be above 2MB", path: ["imageUrl"] },
			]);
		}
	};

	const onClickAddDesign = (event) => {
		event.preventDefault();
		setErrors([]);

		const schema = Joi.object({
			productName: Joi.string().required().messages({
				"string.empty": "Product Name is required",
			}),
			sku: Joi.string().required().messages({
				"string.empty": "SKU is required",
			}),
			price: Joi.number().min(1).required().messages({
				"number.min": "Price must be greater than 1",
			}),
			details: Joi.string().required().messages({
				"string.empty": "Details is required",
			}),
			type: Joi.string().required().messages({
				"string.empty": "Type is required",
			}),
			imageUrl: Joi.string().required().messages({
				"string.empty": "Please select the image.",
			}),
		});

		const { error } = schema.validate(
			{ productName, price, sku, details, type, imageUrl },
			{ abortEarly: false }
		);

		if (error) {
			setErrors(error.details);
			return;
		}

		const formData = new FormData();

		formData.append("sku", sku);
		formData.append("type", type);
		formData.append("productName", productName);
		formData.append("price", price);
		formData.append("addToShopify", addToShopify);
		formData.append(
			"continueSellingWhenOutOfStock",
			continueSellingWhenOutOfStock
		);
		formData.append("details", details);
		formData.append("image", imageData);

		if (designData) {
			formData.append("productId", designData._id);
			dispatch(editDesignThunk({ formData, JWT: state.loginToken }));        // Edit Dispatch
		} else {
			dispatch(createNewDesignThunk({ formData, JWT: state.loginToken }));   // Add Dispatch
			clearForm();
		}
	};


	function convertBase64ToBlob (base64String) {
		const byteCharacters = window.atob(base64String);
		const byteArrays = [];

		for (let i = 0; i < byteCharacters.length; i++) {
			byteArrays.push(byteCharacters.charCodeAt(i));
		}

		const byteArray = new Uint8Array(byteArrays);
		const blob = new Blob([byteArray]);
		return blob;
	}

	return (
		<Card
			className="card-border"
			style={{
				borderRadius: "16px",
				padding: "1rem",
			}}
			fullWidth
		>
			<CardContent sx={{ padding: ".8rem !important" }} >

				<div className="ai-bot-card-body">
					<form className="form">
						<div className="row mb-3">
							<div className="col-6">
								<TextField
									label={"Product Name"}
									onChange={onChangeProductName}
									value={productName}
									size="small"
									name="Product Name"
									fullWidth
								/>
								<p className="text-danger">
									{errors?.find((ele) => ele.path[0] === "productName")?.message}
								</p>
							</div>

							<div className="col-6">
								<TextField
									label={"SKU Number"}
									onChange={onChangeSku}
									value={sku}
									size="small"
									name="SKU Number"
									fullWidth
								/>
								<p className="text-danger">
									{errors?.find((ele) => ele.path[0] === "sku")?.message}
								</p>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-6">
								<TextField
									label={"Price"}
									onChange={onChangePrice}
									value={price}
									size="small"
									type="number"
									name="Price"
									fullWidth
								/>
								<p className="text-danger">
									{errors?.find((ele) => ele.path[0] === "price")?.message}
								</p>
							</div>
							<div className="col-6">
								<TextField
									label={"Type"}
									onChange={onChangeType}
									value={type}
									size="small"
									name="Type"
									fullWidth
								/>
								<p className="text-danger">
									{errors?.find((ele) => ele.path[0] === "type")?.message}
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<TextField
									label={"Details"}
									onChange={onChangeDetails}
									value={details}
									multiline={true}
									size="small"
									name="Details"
									rows={7}
									fullWidth
								/>

								<p className="text-danger">
									{errors?.find((ele) => ele.path[0] === "details")?.message}
								</p>
							</div>

							<div className="col-6">
								<div>
									<label className="form-label">Design Image</label>
								</div>
								<div className="d-flex">
									<input
										id="selectImage"
										type="file"
										onChange={onImageChange}
										accept="image/x-png,image/gif,image/jpeg"
									/>
								</div>
								<div>
									<img
										src={imageUrl}
										width={120}
										alt=""
										style={{ objectFit: "cover", marginTop: "10px" }}
									></img>
								</div>
								<div>
									<p className="text-danger">
										{errors?.find((ele) => ele.path[0] === "imageUrl")?.message}
									</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<FormControlLabel
									control={
										<Checkbox
											checked={addToShopify}
											onChange={(e) => setAddToShopify(e.target.checked)}
											name="Add To Shopify"
											color="primary"
										// size="small"
										/>
									}
									label="Add To Shopify"
								/>
							</div>
							<div className="col-6">
								<FormControlLabel
									control={
										<Checkbox
											checked={continueSellingWhenOutOfStock}
											onChange={(e) =>
												setContinueSellingWhenOutOfStock(e.target.checked)
											}
											name="Continue selling when out of stock"
											color="primary"
										// size="small"
										/>
									}
									label="Continue selling when out of stock"
								/>
							</div>
						</div>

						{!designData ?

							// ----------------------------- Add Design Ui --------------------------------
							<>
								<div className="row mt-2">
									<div className="col-12">
										{state.showAddDesignLoader ? (
											<div className="centered">
												<div className="spinner-border mx-2"></div>
												<span>Adding Design...</span>
											</div>
										) : (
											<Button
												variant="contained"
												size="medium"
												className="mt-3"
												startIcon={<SaveIcon />}
												onClick={onClickAddDesign}
											>
												Save Design
											</Button>
										)}
									</div>
								</div>
								{state.addDesignErrorMessage && (
									<div className="row mt-1">
										<div className="col-12">
											<p className="text-danger">{state.addDesignErrorMessage}</p>
										</div>
									</div>
								)}
							</>
							// ----------------------------- Add Design Ui --------------------------------

							:

							// ----------------------------- Edit Design Ui --------------------------------

							<>
								<div className="d-flex justify-content-end mt-2">
									{state.showEditDesignLoader ? (
										<Button
											className="me-2"
											variant="contained"
											color="success"
										>
											Updating Changes...
										</Button>
									) : (
										<Button
											className="me-3"
											variant="contained"
											color="success"
											onClick={onClickAddDesign}
										>
											Edit Design
										</Button>
									)}
									<Button
										variant="contained"
										color="error"
										onClick={() => dispatch(closeEditDesignModal())}
									>
										Cancel
									</Button>
								</div>

								<p className="text-danger centered">
									{state.editDesignErrorMessage}
								</p>
							</>

							// ----------------------------- Edit Design Ui --------------------------------

						}
					</form>
				</div>
			</CardContent>
		</Card>
	);
}

export default NewDesignComponent;
