import React, { useEffect, useState } from "react";
import _debounce from "lodash/debounce";

import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryThunk } from "../store/chatBotCreatorThunk";
import { Card, CardContent, Pagination, TextField } from "@mui/material";

function InventoryCard({ inventoryData, index }) {
  const titleLength = [0];

  return (
    <Card key={index} className="card-border" fullWidth>
      <CardContent sx={{ padding: ".8rem !important" }}>
        <div className="row">
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
            {inventoryData.image?.map((element, index) => index < 4 && <img src={element} className="mb-2 col-8" alt="" height={50} width={50} style={{ objectFit: "cover" }} />)}
          </div>
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
            <div className="row">
              <div className="col-12">
                <b className="text-primary">{inventoryData.productName}</b>
              </div>
              <div className="row">
                {titleLength?.map((element, index) => (
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="row mt-2">

                      <div className="col-4">
                        <b>&nbsp; &nbsp; &nbsp; &nbsp; Variants:</b>
                      </div>

                      <div className="col-4">
                        <b>SKU:</b>
                      </div>

                      <div className="col-2">
                        <b>Quantity:</b>
                      </div>

                      <div className="col-2">
                        <b>Racks:</b>
                      </div>

                    </div>

                  </div>
                ))}
                {inventoryData.variantDetails?.map((element, index) => (
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="row mt-2">
                      <div className="col-4">
                        <b>{index + 1}.</b> &nbsp; &nbsp; &nbsp;
                        {element.option1} / {element.option2}
                      </div>

                      <div className="col-4">{element.sku}</div>

                      <div className="col-2">{element.inventory}</div>
                      <div className="col">
                        {inventoryData.variantDetails[index].rackCollection.length > 0 ? (
                          inventoryData.variantDetails[index].rackCollection?.map((arrElement, arrIndex) => (
                            <div className="row-2 ">
                              {arrElement.floorNumber} : {arrElement.rackNumber}
                            </div>
                          ))
                        ) : (
                          <div className="row-2 ">&nbsp; &nbsp; --</div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function ListInventoryComponent() {

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedProductName, setSearchedProductName] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const state = useSelector((state) => state.chatBotCreatorStore);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getAllInventory = async () => {
    dispatch(getAllInventoryThunk({ JWT: state.loginToken, searchedProductName,currentPage, pageSize: 10 }));
  };
  
  const debouncedSearch = _debounce((value) => {
    setDebouncedValue(value);
  }, 1000);

  useEffect(() => {
    getAllInventory();
    debouncedSearch(searchedProductName);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchedProductName]);


  useEffect(() => {
    getAllInventory();
  }, [currentPage]);

  return (
    <div style={{ height: "92%" }}>
      <div className="row mt-2">
        <div className="col-12">
          <div>
            <TextField label={"Search By Product Name"} size="small" name="Details" value={searchedProductName} onChange={(e) => setSearchedProductName(e.target.value)} fullWidth></TextField>
          </div>
        </div>
      </div>
      {state.showGetAllInventoryLoader ? (
        <div className="centered" style={{ height: "300px" }}>
          <div className="centered" style={{ height: "300px" }}>
            <div className="spinner-border mx-2"></div>
            <span>Loading Inventory List...</span>
          </div>
        </div>
      ) : (
        <div className="col-12" style={{ height: "100%", overflowY: "scroll" }}>
          <div className="row d-flex">
            {state.getAllInventoryErrorMessage === "" ? (
              state.inventoryList?.length > 0 ? (
                state.inventoryList?.map((inventoryData, index) => (
                  <div key={index} className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 my-2">
                    <InventoryCard index={index} inventoryData={inventoryData} />
                  </div>
                ))
              ) : (
                <div>No Inventory Found. Please Add Designs.</div>
              )
            ) : (
              <p className="text-danger mt-1">{state.getAllInventoryErrorMessage}</p>
            )}
          </div>
          <div className="my-2 d-flex justify-content-end" style={{ position: "fixed", right: 10, bottom: 0 }}>
            <Pagination
              count={parseInt(Math.ceil(state.totalInventoryCount / 10)) > 10 ? Math.ceil(state.totalInventoryCount / 10) : Math.ceil(state.totalInventoryCount / 10)}
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ListInventoryComponent;
