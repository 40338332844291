import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewBotActionToBladeActions, onClickEditActionItem } from "../../store/chatBotCreatorSlice";

function ActionViewItem({ childAction, onBotActionSelectedCallback, parentBotActionId }) {

  const state = useSelector((state) => state.chatBotCreatorStore);
  const onBotActionSelected = () => {
    onBotActionSelectedCallback(childAction);
  };

  let currentBotActionName = childAction.botActionName.find((ele) => ele.locale === state.currentLocale).text;


  return (
    <div onClick={onBotActionSelected} className="ai-bot-action-item">
      <button className="btn ps-0" style={{ color: `${childAction.isSelected ? "#1275FF" : "grey"}` }}>
        <FontAwesomeIcon icon={childAction.icon} />
      </button>
      <label style={{ cursor: "pointer", width: "fit-content" }} className="card-title-header">
        {currentBotActionName}
      </label>
    </div>
  );
}

export default function ActionPanel({ botAction }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const onBotActionSelected = (childAction) => {
    if (childAction.isSelected) {
      return; // return if it is already selected
    }

    dispatch(
      addNewBotActionToBladeActions({
        parentBotActionId: botAction.botActionId,
        childActionId: childAction.botActionId,
        JWT: state.loginToken
      })
    );
  };

  return (
    <div className="ai-bot-action-card">
      {botAction.childActions.map((childAction, key) => (
        childAction.role.includes(state.userRole) &&
        <div key={key} className={childAction.isSelected ? "action-item-view-selected" : ""}>
          <ActionViewItem childAction={childAction} onBotActionSelectedCallback={onBotActionSelected} parentBotActionId={botAction.botActionId} />
        </div>
      ))}
    </div>
  );
}
