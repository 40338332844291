import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewUserThunk, editUserThunk, getAllRacksByFloorThunk, getAllUsersThunk } from "../store/chatBotCreatorThunk";
import Joi from "joi";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Button, Card, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { closeEditUserModal } from "../store/chatBotCreatorSlice";

export function NewUserComponent({ userData }) {

  
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);
  const [username, setUsername] = useState(userData ? userData.username : "");
  const [firstName, setFirstName] = useState(userData ? userData.firstname : "");
  const [lastName, setLastName] = useState(userData ? userData.lastname : "");
  const [phone, setPhone] = useState(userData ? userData.phone : "");
  const [code, setCode] = useState(userData ? userData.code : "");
  const [pin, setPin] = useState(userData ? userData.pin : "");
  const [role, setRole] = useState(userData?.role ? userData?.role : "");
  // const [floor, setFloor] = useState(userData?.floor ? userData.floor : "Select Floor");
  const [floors, setFloors] = useState(userData?.floors ? userData.floors : []);
  const [packager, setPackager] = useState(userData?.packagerId ? userData?.packagerId : "");
  const [password, setPassword] = useState(userData ? userData.password : "");
  const [details, setDetails] = useState(userData ? userData.details : "");
  const [errors, setErrors] = useState([]);
  const [imageData, setImageData] = useState(userData && userData?.image !== "data:undefined;base64,undefined" ? convertBase64ToBlob(userData.image?.split(",")[1]) : "");
  const [imageUrl, setImageUrl] = useState(userData ? userData.image : "");

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
    const index = errors.findIndex((ele) => ele.path[0] === "username");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  function convertBase64ToBlob(base64String) {
    const byteCharacters = window.atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    const blob = new Blob([byteArray]);
    return blob;
  }

  const onChangeFirstName = (e) => {
    setFirstName(e.target.value);
    const index = errors.findIndex((ele) => ele.path[0] === "firstName");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  const onChangeLastName = (e) => {
    setLastName(e.target.value);
    const index = errors.findIndex((ele) => ele.path[0] === "lastName");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  const onChangePhone = (e) => {
    setPhone(e.target.value);
    const index = errors.findIndex((ele) => ele.path[0] === "phone");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    const index = errors.findIndex((ele) => ele.path[0] === "password");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  const onChangeRole = (e) => {
    setRole(e.target.value);
    setPackager("");
    const index = errors.findIndex((ele) => ele.path[0] === "role");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };


  const onChangeFloor = (e) => {
    const selectedFloor = e.target.value;
    if (selectedFloor !== "" && selectedFloor) {
      const floorIndex = floors?.findIndex((item) => item === selectedFloor);
      if (floorIndex >= 0) {
        let newArrray= [...floors];
        newArrray.splice(floorIndex, 1);
        setFloors(newArrray);
      } else {
        setFloors([...floors, selectedFloor]);
      }
    }
  };

  const onChangePackager = (e) => {
    setPackager(e.target.value);
    const index = errors.findIndex((ele) => ele.path[0] === "packager");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  const clearForm = () => {
    setImageUrl("");
    setCode("");
    setDetails("");
    setPassword("");
    setPin("");
    setRole("");
    setFloors([]);
    setPackager("");
    setFirstName("");
    setLastName("");
    setUsername("");
    setPhone("");
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0] && event.target.files[0].size < 2097152) {
      const index = errors.findIndex((ele) => ele.path[0] === "imageUrl");
      errors.splice(index, 1);
      const url = URL.createObjectURL(event.target.files[0]);
      setImageUrl(url);
      setImageData(event.target.files[0]);
    } else {
      setErrors([...errors, { message: "Image size cannot be above 2MB", path: ["imageUrl"] }]);
    }
  };

  const onAddNewUser = () => {
    setErrors([]);

    const schema = Joi.object({
      username: Joi.string().required().messages({
        "string.empty": "Username is required",
      }),
      firstName: Joi.string().required().messages({
        "string.empty": "First Name is required",
      }),
      lastName: Joi.string().required().messages({
        "string.empty": "Last Name is required",
      }),
      phone: Joi.string().required().messages({
        "string.empty": "Phone is required",
      }),
      role: Joi.string().invalid("Select Role").messages({
        "any.invalid": "Role is required",
      }),
      password: Joi.string().required().messages({
        "string.empty": "Password is required",
      }),

      floors: Joi.array().when("role", {
        is: "employee",
        then: Joi.array().invalid("Select Floor").messages({
          "any.invalid": "Floor is required",
        }),
        otherwise: Joi.array().strip(),
      }),
      packager: Joi.string().when("role", {
        is: "employee",
        then: Joi.string().invalid("Select Packager").messages({
          "any.invalid": "Packager is required",
        }),
        otherwise: Joi.string().strip().allow(''),
      }),
    });

    const { error } = schema.validate({ username, phone, role, password, firstName, lastName, floors, packager }, { abortEarly: false });
    console.log(error);
    if (error) {
      setErrors(error.details);
      return;
    }

    const formData = new FormData();

    formData.append("username", username);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("phone", phone);
    formData.append("code", code);
    formData.append("pin", pin);
    formData.append("role", role);
    formData.append("floors", JSON.stringify(floors));
    formData.append("packager", packager);
    formData.append("password", password);
    formData.append("details", details);
    formData.append("image", imageData);

    if (userData) {
      formData.append("userId", userData._id);
      dispatch(editUserThunk({ JWT: state.loginToken, formData }));
      setTimeout(() => {
        dispatch(getAllUsersThunk({ JWT: state.loginToken, currentPage: 1, pageSize: 10 }));
      }, 500);
    } else {
      dispatch(createNewUserThunk({ formData, JWT: state.loginToken })); // Add Dispatch
      clearForm();
    }
  };

  useEffect(() => {
    dispatch(getAllRacksByFloorThunk({ JWT: state.loginToken }));
    dispatch(getAllUsersThunk({ JWT: state.loginToken }));
  }, []);

  console.log(floors);

  return (
    <Card className="card-border me-2" fullWidth style={{ height: "100%", overflowY: "scroll" }}>
      <div className="m-2">
        <div className="row mb-3 mt-3">
          <div className="col-8">
            <TextField label={"Username"} onChange={onChangeUsername} value={username} size="small" name="Username" fullWidth />
            <p className="text-danger">{errors?.find((ele) => ele.path[0] === "username")?.message}</p>
          </div>
          <div className="col-4">
            <TextField label={"Phone"} onChange={onChangePhone} value={phone} size="small" name="Phone" fullWidth />
            <p className="text-danger">{errors?.find((ele) => ele.path[0] === "phone")?.message}</p>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-6">
            <TextField label={"First Name"} onChange={onChangeFirstName} value={firstName} size="small" name="firstName" fullWidth />
            <p className="text-danger">{errors?.find((ele) => ele.path[0] === "firstName")?.message}</p>
          </div>

          <div className="col-6">
            <TextField label={"Last Name"} onChange={onChangeLastName} value={lastName} size="small" name="lastName" fullWidth />
            <p className="text-danger">{errors?.find((ele) => ele.path[0] === "lastName")?.message}</p>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-2">
            <TextField label={"Code"} onChange={(e) => setCode(e.target.value)} value={code} size="small" name="Code" fullWidth />
          </div>

          <div className="col-3">
            <FormControl fullWidth>
              <InputLabel id="select-role" size="small">
                Select Role
              </InputLabel>
              <Select labelId="select-role" label="Select Role" id="select-input" onChange={onChangeRole} size="small" fullWidth>
                <MenuItem value="employee">Employee</MenuItem>
                <MenuItem value="packager">Packager</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>

            <p className="text-danger">{errors?.find((ele) => ele.path[0] === "role")?.message}</p>
          </div>
          {/* {role === "employee" && (
            <div className="col-3">
              <Select labelId="select-floor" id="select-input" onChange={onChangeFloor} size="small" value={floor} fullWidth>
                <MenuItem value="Select Floor">Select Floor</MenuItem>
                {state.rackListByFloor.map((element) => (
                  <MenuItem value={element._id}>{element._id}</MenuItem>
                ))}
              </Select>
              <p className="text-danger">{errors?.find((ele) => ele.path[0] === "floor")?.message}</p>
            </div>
          )} */}

          {role === "employee" && (
            <div className="col-3">
              <FormControl fullWidth>
                <InputLabel id="select-floor" size="small">
                  Select Floor
                </InputLabel>
                <Select labelId="select-floor" label="Select Floor" id="select-input" size="small" onChange={onChangeFloor} fullWidth>
                  {state.rackListByFloor.map((element) => (
                    <MenuItem value={element._id}>
                      {element._id}
                      <Checkbox className="justify-content-end text-align-end" checked={floors.includes(element._id)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p className="text-danger">{errors?.find((ele) => ele.path[0] === "floor")?.message}</p>
            </div>
          )}
          {role === "employee" && (
            <div className="col-4">
              <FormControl fullWidth>
                <InputLabel id="select-packager" size="small">
                  Select Packager
                </InputLabel>
                <Select labelId="select-input" label="Select Packager" id="select-input" size="small" fullWidth value={packager} onChange={onChangePackager}>
                  {state.userList.map((userData, index) =>
                    userData.role === "packager" ? (
                      <MenuItem key={index} value={userData._id}>
                        {userData.firstname} {userData.lastname}
                      </MenuItem>
                    ) : null
                  )}
                </Select>
              </FormControl>
              <p className="text-danger">{errors?.find((ele) => ele.path[0] === "packager")?.message}</p>
            </div>
          )}
        </div>

        <div className="row mb-3">
          <div className="col-8">
            <TextField label={"Password"} onChange={onChangePassword} value={password} size="small" name="Password" type="password" fullWidth />
            <p className="text-danger">{errors?.find((ele) => ele.path[0] === "password")?.message}</p>
          </div>
          <div className="col-4">
            <TextField label={"Pin"} onChange={(e) => setPin(e.target.value)} value={pin} size="small" name="Pin" fullWidth />
            <p className="text-danger">{errors?.find((ele) => ele.path[0] === "pin")?.message}</p>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-8">
            <TextField label={"Details"} onChange={(e) => setDetails(e.target.value)} value={details} multiline={true} size="small" name="Details" rows={7} fullWidth />
            <p className="text-danger">{errors?.find((ele) => ele.path[0] === "details")?.message}</p>
          </div>

          <div className="col-4">
            <div>
              <label className="form-label">Photo</label>
            </div>
            <div>
              <input id="selectImage" type="file" onChange={onImageChange} accept="image/x-png,image/gif,image/jpeg" />
            </div>
            <div>
              <img src={imageUrl} height={160} alt="" style={{ objectFit: "cover", marginTop: "10px" }}></img>
              <p className="text-danger">{errors?.find((ele) => ele.path[0] === "imageUrl")?.message}</p>
            </div>
          </div>
        </div>

        {
          !userData ? (
            // ----------------------------- Add User Ui --------------------------------
            <>
              <div className="row mt-3">
                <div className="col-12">
                  {state.showAddUserLoader ? (
                    <div>
                      <div className="spinner-border mx-2"></div>
                      <span>Adding User...</span>
                    </div>
                  ) : (
                    <Button variant="contained" size="medium" startIcon={<PersonAddIcon />} icon={faUserPlus} onClick={onAddNewUser}>
                      Add User
                    </Button>
                  )}
                </div>
              </div>
              {state.addUserErrorMessage && (
                <div className="row mt-1">
                  <div className="col-12">
                    <p className="text-danger">{state.addUserErrorMessage}</p>
                  </div>
                </div>
              )}
            </>
          ) : (
            // ----------------------------- Add User Ui --------------------------------

            // ----------------------------- Edit User Ui --------------------------------
            <>
              <div className="d-flex justify-content-center mt-2">
                {state.showEditUserLoader ? (
                  <Button className="me-2" variant="contained" color="success">
                    Updating...
                  </Button>
                ) : (
                  <Button className="me-3" variant="contained" color="success" onClick={onAddNewUser}>
                    Edit User
                  </Button>
                )}
                <Button variant="contained" color="error" onClick={() => dispatch(closeEditUserModal())}>
                  Cancel
                </Button>
              </div>

              {state.editUserErrorMessage !== "" && <p className="text-danger centered mt-3">{state.editUserErrorMessage}</p>}
            </>
          )
          // ----------------------------- Edit User Ui --------------------------------
        }
      </div>
    </Card>
  );
}

export default NewUserComponent;
