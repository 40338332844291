import {
  faCartPlus,
  faCartShopping,
  faSave,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewOrderThunk,
  getAllDesignsThunk,
} from "../store/chatBotCreatorThunk";
import Joi from "joi";
import { Card, TextField } from "@mui/material";

function ItemListing({ itemCollection, setItemCollection }) {
  const onClickDeleteIcon = (index) => {
    let newItems = [...itemCollection];
    if (index >= 0) {
      newItems.splice(index, 1);
      setItemCollection(newItems);
    }
  };

  const onChangeItemQuantity = (event, index) => {
    let newItem = itemCollection[index];
    newItem.quantity = event.target.value;
    setItemCollection([...itemCollection]);
  };

  return (
    <div className="mb-4">
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th className="col-1">Sr.</th>
            <th className="col-6">SKU</th>
            <th className="col-4 icon">Quantity</th>
            <th className="col-1 icon">Action</th>
          </tr>
        </thead>
        <tbody>
          {itemCollection.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td className="col-1">{item.sku}</td>
              <td className="col-1">
                <input
                  value={item.quantity}
                  onChange={(event) => onChangeItemQuantity(event, index)}
                />
              </td>
              <td className="col-1">
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="icon"
                  onClick={() => onClickDeleteIcon(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function AddOrderForm({
  sku,
  setSku,
  quantity,
  setQuantity,
  customerName,
  setCustomerName,
  customerPhone,
  setCustomerPhone,
  customerAddress,
  setCustomerAddress,
  itemCollection,
  setItemCollection,
  errors,
  setErrors,
}) {
  const state = useSelector((state) => state.chatBotCreatorStore);
  const dispatch = useDispatch();

  const [addItemClicked, setAddItemClicked] = useState(false);

  const clearForm = () => {
    setSku("");
    setCustomerName("");
    setCustomerPhone("");
    setCustomerAddress("");
    setItemCollection([]);
  };

  const onChangeCustomerName = (e) => {
    setCustomerName(e.target.value);
    const index = errors.findIndex((ele) => ele.path[0] === "customerName");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  const onChangeCustomerPhone = (e) => {
    setCustomerPhone(e.target.value);
    const index = errors.findIndex((ele) => ele.path[0] === "customerPhone");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  const onChangeCustomerAddress = (e) => {
    setCustomerAddress(e.target.value);
    const index = errors.findIndex((ele) => ele.path[0] === "customerAddress");
    if (index >= 0) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  const onClickSaveOrder = () => {
    setErrors([]);
    const schema = Joi.object({
      customerName: Joi.string().required().messages({
        "string.empty": "customerName is required",
      }),
      customerPhone: Joi.string().required().messages({
        "string.empty": "customerPhone is required",
      }),
      customerAddress: Joi.string().required().messages({
        "string.empty": "customerAddress is required",
      }),
      itemCollection: Joi.array().min(1).messages({
        "array.min": "Atleast 1 item should be added",
      }),
    });

    const { error } = schema.validate(
      { customerName, customerPhone, customerAddress, itemCollection },
      { abortEarly: false }
    );
    if (error) {
      setErrors(error.details);
      return;
    }
    dispatch(
      createNewOrderThunk({
        customerName,
        customerPhone,
        customerAddress,
        itemCollection,
        JWT: state.loginToken
      })
    );
    clearForm();
  };

  const onClickAddItem = async () => {
    setAddItemClicked(true);

    if (sku === "" || quantity <= 0) {
      return;
    } else {
      setItemCollection([...itemCollection, { sku, quantity }]);
      setQuantity(0);
      setAddItemClicked(false);
      setSku("");

      const index = errors.findIndex((ele) => ele.path[0] === "itemCollection");
      if (index >= 0) {
        errors.splice(index, 1);
        setErrors(errors);
      }
    }
  };

  return (
    <div className="mx-2 mt-2">
      <div className="d-flex mb-1">
        <div style={{ width: "75%" }}>
          <TextField
            label={"Customer Name"}
            value={customerName}
            onChange={onChangeCustomerName}
            size="small"
            type="number"
            name="Customer Name"
            fullWidth
          />
          {/* <label className="form-label">Customer Name</label>
          <input
            type="text"
            className="form-control"
            value={customerName}
            onChange={onChangeCustomerName}
          /> */}
          <p className="text-danger">
            {errors?.find((ele) => ele.path[0] === "customerName")?.message}
          </p>
        </div>
        <div className="col-3 ms-2">
          <label className="form-label">Customer Phone</label>
          <input
            type="text"
            className="form-control"
            value={customerPhone}
            onChange={onChangeCustomerPhone}
          />
          <p className="text-danger">
            {errors?.find((ele) => ele.path[0] === "customerPhone")?.message}
          </p>
        </div>
      </div>
      <div className="mb-1">
        <label className="form-label">Customer Address</label>
        <textarea
          type="text"
          className="form-control"
          value={customerAddress}
          onChange={onChangeCustomerAddress}
        />
        <p className="text-danger">
          {errors?.find((ele) => ele.path[0] === "customerAddress")?.message}
        </p>
      </div>

      {itemCollection.length > 0 && (
        <ItemListing
          itemCollection={itemCollection}
          setItemCollection={setItemCollection}
        />
      )}

      <div className="d-flex">
        <div className="col-6">
          <label className="form-label">SKU</label>
          <select
            className="form-control"
            onChange={(e) => setSku(e.target.value)}
            value={sku}
          >
            <option value={""}>--- Select SKU ---</option>
            {state.designList?.map((designData, key) => (
              <option value={designData.sku}>{designData.sku}</option>
            ))}
          </select>
          {sku === "" && addItemClicked && (
            <p className="text-danger">Please select SKU.</p>
          )}
        </div>

        <div className="col-6">
          <label className="form-label">Quantity</label>
          <div className="d-flex">
            <div>
              <input
                type="number"
                className="form-control mx-2"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                style={{ width: "70%" }}
              />
            </div>
            <button className="btn btn-secondary" onClick={onClickAddItem}>
              <FontAwesomeIcon icon={faCartPlus} />
              &nbsp; Add New Item
            </button>
          </div>
          {quantity <= 0 && addItemClicked && (
            <p className="text-danger">Quantity should be greater than 0.</p>
          )}
        </div>
      </div>
      <p className="text-danger">
        {errors?.find((ele) => ele.path[0] === "itemCollection")?.message}
      </p>
      <div className="mt-4 d-flex justify-content-between">
        {state.showAddOrderLoader ? (
          <div className="centered">
            <div className="spinner-border mx-2"></div>
            <span>Saving Order...</span>
          </div>
        ) : (
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={onClickSaveOrder}
          >
            <FontAwesomeIcon icon={faSave} />
            &nbsp; Save Order
          </button>
        )}
      </div>
      <p className="text-danger mt-1">{state.addOrderErrorMessage}</p>
    </div>
  );
}

function NewOrderComponent() {
  const dispatch = useDispatch();
  const state = useSelector(state => state.chatBotCreatorStore)

  const [sku, setSku] = useState("");
  const [itemCollection, setItemCollection] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [errors, setErrors] = useState([]);

  const getImages = async () => {
    dispatch(getAllDesignsThunk({ JWT: state.loginToken }));
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <Card
      className="card-border"
      fullWidth
    >
      <AddOrderForm
        sku={sku}
        setSku={setSku}
        quantity={quantity}
        setQuantity={setQuantity}
        customerName={customerName}
        setCustomerName={setCustomerName}
        customerPhone={customerPhone}
        setCustomerPhone={setCustomerPhone}
        customerAddress={customerAddress}
        setCustomerAddress={setCustomerAddress}
        itemCollection={itemCollection}
        setItemCollection={setItemCollection}
        errors={errors}
        setErrors={setErrors}
      />
    </Card>
  );
}

export default NewOrderComponent;
