import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  addRacksToDesignThunk,
  deleteDesignThunk, //fsff
  getAllDesignsThunk,
  getAllRacksThunk,
} from "../store/chatBotCreatorThunk";
import moment from "moment/moment";
import { Modal } from "react-bootstrap";
import _debounce from "lodash/debounce";
import { Button, Card, CardContent, Checkbox, FormControlLabel, Pagination, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import NewDesignComponent from "./NewDesignComponent";
import { closeDeleteDesignModal, closeEditDesignModal, showDeleteDesignModal, showEditDesignModal } from "../store/chatBotCreatorSlice";

function DesignCard({ index, designData, setShowRackModel, setSkuDetails, setRackCollection, setSelectedDesignData, setVariant_ID }) {
  const dispatch = useDispatch();

  const onClickSelectRack = (index) => {
    setRackCollection(designData.variantDetails[index].rackCollection);
    setSkuDetails(designData);
    setVariant_ID(designData.variantDetails[index].variantId);

    setShowRackModel(true);
  };

  const onClickEditDesign = () => {
    setSelectedDesignData(designData);
    dispatch(showEditDesignModal());
  };

  const onClickDeleteDesign = () => {
    setSelectedDesignData(designData);
    dispatch(showDeleteDesignModal());
  };

  return (
    <Card
      key={index}
      className="card-border my-2"
      style={{
        borderRadius: "16px",
        padding: "1rem",
      }}
      fullWidth
    >
      <CardContent sx={{ padding: ".8rem !important" }} key={index}>
        <div className="row">
          <div className="col-4">
            <div className="row">
              <div className="col-12">
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="sub-heading">{designData?.productName}</div>
                  </div>
                  <div className="col-12 mt-2">Added On: {moment(designData?.createdDateTime).format("lll")}</div>
                  <div className="col-12">Updated On: {moment(designData?.updatedDateTime).format("lll")}</div>
                  <div className="row mt-3">
                    {designData?.image.map(
                      (image, index) =>
                        index < 4 && (
                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 me-4">
                            <img src={image} alt="" height={90} width={70} style={{ objectFit: "cover" }} />
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-4 mb-3">
                <b>Variants</b>
              </div>
              <div className="col-2 mb-3">
                <b>Price</b>
              </div>
              <div className="col-3 mb-3">
                <b>SKU</b>
              </div>
              <div className="col-3 mb-3">
                <b>Racks</b>
              </div>
            </div>
            {designData?.variantDetails?.map((element, index) => (
              <div className="row">
                <div className="col-4 mb-3">
                  <b>{index + 1}.</b>&nbsp;{element?.option1} / {element?.option2}
                </div>
                <div className="col-2 mb-3">Rs. {element?.price}</div>
                <div className="col-3 mb-3">{element?.sku}</div>
                <div className="col-3 mb-3">
                  <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} size="small" onClick={() => onClickSelectRack(index)}>
                    Edit Rack
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function ListDesignsComponent() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const [skuDetails, setSkuDetails] = useState(null);
  const [showRackModel, setShowRackModel] = useState(false);
  const [rackCollection, setRackCollection] = useState([]);
  const [selectedDesignData, setSelectedDesignData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [variantId, setVariant_ID] = useState("");
  const [searchedProductName, setSearchedProductName] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const getAllDesigns = async () => {
    dispatch(
      getAllDesignsThunk({
        JWT: state.loginToken,
        currentPage,
        searchedProductName,
        pageSize: 10,
      })
    );
    dispatch(getAllRacksThunk({ JWT: state.loginToken }));
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  
  const addRackToDesign = async() => {
    dispatch(
      addRacksToDesignThunk({
        variantId: variantId,
        rackCollection,
        JWT: state.loginToken,
      })
    );

    setShowRackModel(false);
    setRackCollection([]);
  };
  const onSelectRack = (e, floorNumber, rackNumber, index) => {
    if (e.target.checked) {
      setRackCollection([...rackCollection, { floorNumber, rackNumber }]);
    } else {
      let newRackCollection = [...rackCollection];
      const indexToBeEditd = newRackCollection.findIndex((ele) => ele.floorNumber === floorNumber && ele.rackNumber === rackNumber);
      newRackCollection.splice(indexToBeEditd, 1);
      setRackCollection(newRackCollection);
    }
  };


  const handleHide = async (value) => {
    setShowRackModel(value);
    await addRackToDesign();
  };



  const debouncedSearch = _debounce((value) => {
    setDebouncedValue(value);
  }, 1000);

  useEffect(() => {
    // getAllDesigns();
    debouncedSearch(searchedProductName);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchedProductName]);

  useEffect(() => {
    getAllDesigns();
  }, [state.reloadDesigns, currentPage, debouncedValue]);

  console.log(state.totalDesignCount, "Total Design Count");

  return (
    <div style={{ height: "92%" }}>
      <div className="row mt-2">
        <div className="col-12">
          <div>
            <TextField label={"Search By Product Name"} size="small" name="Details" value={searchedProductName} onChange={(e) => setSearchedProductName(e.target.value)} fullWidth></TextField>
          </div>
        </div>
      </div>
      {state.showGetAllDesignsLoader ? (
        <div className="centered" style={{ height: "300px" }}>
          <div className="centered" style={{ height: "300px" }}>
            <div className="spinner-border mx-2"></div>
            <span>Loading Design List...</span>
          </div>
        </div>
      ) : (
        <div className="col-12" style={{ height: "100%", overflowY: "scroll" }}>
          <div className="row">
            {state.getAllDesignsErrorMessage === "" ? (
              state.designList?.length > 0 ? (
                state.designList?.map((designData, index) => (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" key={index}>
                    <DesignCard
                      index={index}
                      designData={designData}
                      setShowRackModel={setShowRackModel}
                      setSkuDetails={setSkuDetails}
                      setRackCollection={setRackCollection}
                      setSelectedDesignData={setSelectedDesignData}
                      setVariant_ID={setVariant_ID}
                    />
                  </div>
                ))
              ) : (
                <div>No Design Found. Please Add Designs.</div>
              )
            ) : (
              <p className="text-danger mt-1">{state.getAllDesignsErrorMessage}</p>
            )}
          </div>
          {state.totalDesignCount > 0 && (
            <div className="my-2 d-flex justify-content-end" style={{ position: "fixed", right: 10, bottom: 0 }}>
              <Pagination
                count={Math.ceil(state.totalDesignCount / 10) > 10 ? Math.ceil(state.totalDesignCount / 10) : Math.ceil(state.totalDesignCount / 10)}
                color="primary"
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      )}
      {/* ---------------------- Edit Design Confirmation Modal ----------------------------*/}
      <Modal size="xl" show={state.showEditDesignModal} onHide={() => dispatch(closeEditDesignModal())}>
        <Modal.Header className="modal-bg">
          <Modal.Title className="px-2">Edit Design</Modal.Title>
        </Modal.Header>
        <NewDesignComponent designData={selectedDesignData} />
      </Modal>

      {/* ---------------------- Edit Design Confirmation Modal ----------------------------*/}

      {/* ---------------------- Delete Design Confirmation Modal ----------------------------*/}
      <Modal size="lg" show={state.showDeleteDesignModal} onHide={() => dispatch(closeDeleteDesignModal())}>
        <Modal.Header className="modal-bg">
          <Modal.Title className="px-2">Delete Design</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This design with SKU <b>{selectedDesignData?.sku}</b> will get deleted from shopify. Do you want to continue?
          <div className="d-flex justify-content-end mt-3">
            {state.showDeleteDesignLoader ? (
              <Button className="me-2" variant="contained" color="error">
                Deleting...
              </Button>
            ) : (
              <Button
                className="me-3"
                variant="contained"
                color="error"
                onClick={() =>
                  dispatch(
                    deleteDesignThunk({
                      productId: selectedDesignData._id,
                      JWT: state.loginToken,
                    })
                  )
                }
              >
                Delete
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={() => dispatch(closeDeleteDesignModal())}>
              Cancel
            </Button>
          </div>
          {state.deleteDesignErrorMessage !== "" && <p className="text-danger centered mt-3">{state.deleteDesignErrorMessage}</p>}
        </Modal.Body>
      </Modal>
      {/* ---------------------- Delete Design Confirmation Modal ----------------------------*/}

      {/* -----------------------------  Rack Model ------------------------------ */}

      <Modal size="medium" show={showRackModel} onHide={() => handleHide(false)}  >
        <Modal.Header className="modal-bg">
          <Modal.Title className="px-2" >Choose Rack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div class="row px-3">
              {state.showRackListLoader ? (
                <div className="centered" style={{ height: "300px" }}>
                  <div className="spinner-border mx-2"></div>
                  <span>Loading Rack List...</span>
                </div>
              ) : (
                state.rackList?.map((rack, index) => (
                  <div className="col-6" key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rackCollection?.some((ele) => ele.rackNumber === rack.rackNumber && ele.floorNumber === rack.floorNumber)}
                          onChange={(e) => onSelectRack(e, rack.floorNumber, rack.rackNumber, index)}
                          name={`${rack.floorNumber} : ${rack.rackNumber}`}
                          color="primary"
                          size="small"
                        />
                      }
                      label={`${rack.floorNumber} : ${rack.rackNumber}`}
                    />
                  </div>
                ))
              )}
            </div>
            <div class="row m-4 mb-0">
              {state.showAddRackToDesignLoader ? (
                <div>
                  <div className="spinner-border mt-2 mx-2"></div>
                  <span>Adding Racks...</span>
                </div>
              ) : (
                <>
                  {/* <Button variant="outlined" size="small" startIcon={<SaveIcon />} onClick={addRackToDesign}>
                    Save Changes
                  </Button> */}

                  <p className="text-danger mt-1">{state.addRackToDesignErrorMessage}</p>
                </>
              )}
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ListDesignsComponent;
