import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAllRacksByFloorThunk, getDesignsByFloorAndRackThunk } from "../store/chatBotCreatorThunk";
import { Modal } from "react-bootstrap";
import { Button, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled, tableCellClasses } from "@mui/material";
import { faPencil, faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

const { REACT_APP_SERVER_URL } = process.env;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#eee",
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function DesignCard({ key, designData }) {
  return (
    <Card
      key={key}
      className="card-border my-2"
      style={{
        borderRadius: "16px",
        padding: "1rem",
      }}
      fullWidth
    >
      <CardContent sx={{ padding: ".8rem !important" }}>
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <img src={designData?.designDetails[0]?.image} alt="" height={50} width={50} style={{ objectFit: "cover" }} />
          </div>

          <div className="col-lg-10 col-md-10 col-sm-12">
            <div className="row">
              <div className="col-5">
                <div className="small-font">Product Name</div>
                <div>{designData?.designDetails[0]?.productName}</div>
              </div>
              <div className="col-3">
                <div className="small-font">SKU</div>
                <div>{designData?.sku}</div>
              </div>
              <div className="col-2">
                <div className="small-font">Type</div>
                <div>{designData?.designDetails[0]?.type}</div>
              </div>
              <div className="col-2">
                <div className="small-font">Price</div>
                <div>Rs. {designData?.price}</div>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function RackDetails({ floor, rack, subIndex, index, setSelectedRackNumber, setSelectedFloorNumber, setShowDesignModel }) {
  const [showInput, setShowInput] = useState(false);
  const [newRack, setNewRack] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const updateRack = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_SERVER_URL}/rack/v1/update`,
        { rackId: rack.rackId, floorNumber: floor._id, rackNumber: newRack },
        {
          headers: {
            Authorization: `Bearer ${state.loginToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(getAllRacksByFloorThunk({ JWT: state.loginToken }));
        setShowInput(false);
      }
      // else condition should never occur
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
        setShowInput(false);
      } else {
        throw Error("Server Not Reachable");
      }
    }
  };

  const deleteRack = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_SERVER_URL}/rack/v1/delete`,
        { rackId: rack.rackId },
        {
          headers: {
            Authorization: `Bearer ${state.loginToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(getAllRacksByFloorThunk({ JWT: state.loginToken }));
      }
      // else condition should never occur
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
        setShowInput(false);
      } else {
        throw Error("Server Not Reachable");
      }
    }
  };

  const onClickEditRack = async () => {
    setShowInput(true);
  };

  const onClickViewDesign = (index, subIndex) => {
    setSelectedRackNumber(state.rackListByFloor[index].racks[subIndex].rackNumber);
    setSelectedFloorNumber(state.rackListByFloor[index]._id);
    dispatch(
      getDesignsByFloorAndRackThunk({
        floorNumber: state.rackListByFloor[index]._id,
        rackNumber: state.rackListByFloor[index].racks[subIndex].rackNumber,
        JWT: state.loginToken,
      })
    );
    setShowDesignModel(true);
  };
  return (
    <ul key={subIndex}>
      <li style={{ listStyle: showInput ? "none" : "" }}>
        {showInput ? (
          <TextField label={"Floor Number"} onChange={(e) => setNewRack(e.target.value)} value={newRack} size="small" name="Floor Number" style={{ width: "20%" }} />
        ) : (
          <span style={{ display: "inline-block", minWidth: "80px" }}>{rack.rackNumber}</span>
        )}{" "}
        &nbsp; &nbsp;
        <Button variant="outlined" size="small" startIcon={<VisibilityIcon />} onClick={() => onClickViewDesign(index, subIndex)}>
          View Design
        </Button>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        {showInput ? (
          <FontAwesomeIcon className="icon fa-xl me-3 text-success" icon={faSave} onClick={updateRack} />
        ) : (
          <FontAwesomeIcon className="icon fa-lg me-3" icon={faPencil} onClick={onClickEditRack} />
        )}
        &nbsp; &nbsp;
        <FontAwesomeIcon icon={faTrashCan} className="icon fa-lg text-danger" onClick={deleteRack} />
        <p className="text-danger mt-1">{errorMessage}</p>
      </li>
    </ul>
  );
}

function ListRacksComponents() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const [showDesignModel, setShowDesignModel] = useState(false);
  const [selectedRackNumber, setSelectedRackNumber] = useState("");
  const [selectedFloorNumber, setSelectedFloorNumber] = useState("");

  const getAllRacks = async () => {
    dispatch(getAllRacksByFloorThunk({ JWT: state.loginToken }));
  };

  useEffect(() => {
    getAllRacks();
  }, []);

  return (
    <Card
      className="card-border"
      style={{
        borderRadius: "16px",
        padding: "1rem",
      }}
      fullWidth
    >
      <CardContent sx={{ padding: ".8rem !important", overflowY: "scroll", height: "100%" }}>
        {state.rackListByFloorErrorMessage === "" ? (
          <div className="col-12">
            {state.showRackListByFloorLoader ? (
              <div className="centered" style={{ height: "300px" }}>
                <div className="spinner-border mx-2"></div>
                <span>Loading Order List...</span>
              </div>
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Sr.</StyledTableCell>
                      <StyledTableCell>Floor Number</StyledTableCell>
                      <StyledTableCell>Rack Number</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state?.rackListByFloor.length > 0 &&
                      state?.rackListByFloor?.map((floor, index) => (
                        <TableRow hover key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{floor._id}</TableCell>
                          <TableCell>
                            {floor?.racks.map((rack, subIndex) => (
                              <RackDetails
                                floor={floor}
                                rack={rack}
                                subIndex={subIndex}
                                index={index}
                                setSelectedRackNumber={setSelectedRackNumber}
                                setSelectedFloorNumber={setSelectedFloorNumber}
                                setShowDesignModel={setShowDesignModel}
                              />
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        ) : (
          <p className="text-danger centered">{state.rackListByFloorErrorMessage}</p>
        )}

        {/* -----------------------------  Design List Model ------------------------------ */}
        <Modal   size="xl" show={showDesignModel} onHide={() => setShowDesignModel(false)}>
          <Modal.Header className="modal-bg">
            <Modal.Title className="px-2">
              Designs on Rack {selectedRackNumber} on Floor {selectedFloorNumber}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" >
              {state.showGetDesignsByFloorAndRackLoader ? (
                <div className="centered" style={{ height: "300px" }}>
                  <div className="spinner-border mx-2"></div>
                  <span>Loading Designs For Floor and Rack...</span>
                </div>
              ) : state.getDesignsByFloorAndRackErrorMessage === "" ? (
                state.designListByFloorAndRack?.length > 0 ? (
                  state.designListByFloorAndRack?.map((designData, key) => (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <DesignCard key={key} designData={designData} />
                    </div>
                  ))
                ) : (
                  <div>No Designs Found for this location.</div>
                )
              ) : (
                <p className="text-danger centered">{state.getDesignsByFloorAndRackErrorMessage}</p>
              )}
            </div>
          </Modal.Body>
        </Modal>
        {/* -----------------------------  Design List Model ------------------------------ */}
      </CardContent>
    </Card>
  );
}

export default ListRacksComponents;
