import "./style/chatbotCreatorStyle.css";
import React, { useEffect, useState } from "react";
import loginLogo from "./logo/bmn_-removebg-preview.webp";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { UserLoginThunk } from "./store/chatBotCreatorThunk";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


function LoginCard() {

  const dispatch = useDispatch();
  const botCreatorState = useSelector((state) => state.chatBotCreatorStore);

  const passwordStateChange = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const onClickLogin = () => {
    dispatch(UserLoginThunk({ username, password }));
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <Card
        className="card-border"
        style={{
          borderRadius: "16px",
          padding: "1rem",
        }}
        fullWidth
      >
        <CardHeader style={{ textAlign: "center" }} title="Login" />
        <CardContent>
          <form
            method="post"
            className="form"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="col-12 mb-3">
              <TextField
                label={"Username"}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                name="userName"
                fullWidth
              />
            </div>

            <div className="col-12 mb-3">
              <TextField
                type={showPassword ? "password" : "text"}
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="newPassword"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={passwordStateChange}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="d-flex justify-content-center ">
              {botCreatorState.showLoginLoader ? (
                <Button
                  className="mt-4"
                  variant="contained"
                  fullWidth
                >
                  LOGGIN IN..
                </Button>
              ) : (
                <Button
                  className="mt-4"
                  variant="contained"
                  onClick={onClickLogin}
                  fullWidth
                >
                  LOGIN
                </Button>
              )}
            </div>
            <p className="text-danger centered">
              {botCreatorState.loginErrorMessage}
            </p>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

function App() {
  const navigate = useNavigate();
  const botCreatorState = useSelector((state) => state.chatBotCreatorStore);

  useEffect(() => {
    if (botCreatorState.isUserLogin) {
      navigate(`/dashboard`);
    }
  }, [botCreatorState.isUserLogin]);

  return (
    <div
      className="organization-main-body container-fluid  d-flex align-items-center"
      style={{ width: "100vw" }}
    >
      <div className="col-3"></div>

      <div className="col-3">
        <div style={{ height: "100%", width: "100%" }} className="centered">
          <label className="project-name mx-5" style={{ height: "10%" }}>
            <img src={loginLogo} height={150} alt="Punekar Cotton" />
          </label>
        </div>
      </div>

      <div className="col-3 py-3">
        <LoginCard />
      </div>
    </div>
  );
}

export default App;