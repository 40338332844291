import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import { Button, TextField } from "@mui/material";
import { updateInventoryArray } from "../store/chatBotCreatorSlice";
import { createNewInventoryThunk } from "../store/chatBotCreatorThunk";
import _debounce from "lodash/debounce";
import { useDebounce } from "../utils/debounce";

function NewInventoryComponent() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);
  const [skuNumber, setSkuNumber] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  // const onchangeSkuNumber = (e) => {
  //   setSkuNumber(e.target.value);
  //  dispatch(updateInventoryArray(skuNumber));
  //   // setSkuNumber("");
  // };

  const onchangeSkuNumber = (e) => {
    const value = e.target.value;
    setSkuNumber(value);
    debounceInput(value);
  };

  const debounceInput = useDebounce(() => {
    dispatch(updateInventoryArray(skuNumber));
    setSkuNumber("");
  }, 300);

  const onClickAddInventory = () => {
    dispatch(createNewInventoryThunk({ inventoryArray: state.newInventoryArray, JWT: state.loginToken }));
  };

  return state.showGetAllDesignsLoader ? (
    <div className="centered" style={{ height: "300px" }}>
      <div className="spinner-border mx-2"></div>
      <span>Loading Products...</span>
    </div>
  ) : (
    <div style={{ height: "92%" }}>
      <div>
        <div className="row mb-3">
          <div className="col-12">
            <div>
              <TextField label={"Add Inventory"} size="small" name="Details" value={skuNumber} onChange={onchangeSkuNumber} fullWidth></TextField>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ height: "96%" }}>
        <div className="col-8" style={{ height: "96%", overflowY: "scroll" }}>
          <table class="table table-bordered mb-2">
            <thead style={{ backgroundColor: "#e0edffa8" }} className="text-primary h6">
              <tr>
                <td>Sr.No.</td>
                <td>SKU Number</td>
              </tr>
            </thead>
            <tbody className="bg-white">
              {state.newInventoryArray?.map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{product}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {state.notFoundSku.length > 0 && (
          <div className="col-4" style={{ height: "96%", overflowY: "scroll" }}>
            <table class="table table-bordered mb-2">
              <thead style={{ backgroundColor: "#e0edffa8" }} className="text-danger h6">
                <tr>
                  <td>Sr.No.</td>
                  <td className="text-danger">SKU Number Not Found</td>
                </tr>
              </thead>
              <tbody className="bg-white">
                {state.notFoundSku?.map((sku, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{sku}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="mt-3 d-flex justify-content-between">
        <label className="h5 text-dark" style={{ position: "fixed", bottom: 10 }}>
          Total Items : {state.newInventoryArray.length}
        </label>

        {state.showAddInventoryLoader ? (
          <div style={{ position: "fixed", right: 20, bottom: 10 }}>
            <div className="spinner-border mx-2"></div>
            <span>Adding Inventories...</span>
          </div>
        ) : (
          <Button variant="contained" size="medium" style={{ position: "fixed", right: 20, bottom: 10 }} startIcon={<SaveIcon />} onClick={onClickAddInventory}>
            Add Inventory
          </Button>
        )}
      </div>
    </div>
  );
}

export default NewInventoryComponent;
