import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserThunk, getAllUsersThunk } from "../store/chatBotCreatorThunk";
import { Button, Card, CardContent, Pagination } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import NewUserComponent from "./NewUserComponent";
import { closeDeleteUserModal, closeEditUserModal, showDeleteUserModal, showEditUserModal } from "../store/chatBotCreatorSlice";
import profileLogo from "../logo/profile.png";

function UserCard({ userData, setSelectedUserData }) {
  const dispatch = useDispatch();

  const onClickEditUser = () => {
    setSelectedUserData(userData);
    dispatch(showEditUserModal());
  };

  const onClickDeleteUser = () => {
    setSelectedUserData(userData);
    dispatch(showDeleteUserModal());
  };

  return (
    <Card className="card-border my-2" fullWidth>
      <CardContent sx={{ padding: ".8rem !important" }}>
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
            {userData.image === "data:undefined;base64,undefined" ? (
              <img src={profileLogo} alt="images" height={140} width={100} style={{ objectFit: "cover" }} />
            ) : (
              <img src={userData?.image} alt="" height={140} width={100} style={{ objectFit: "cover" }} />
            )}
          </div>

          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <div className="row">
              <div className="col-9">
                <b>
                  Name: {userData?.firstname} {userData?.lastname}
                </b>
              </div>
              <div className="col-3">
                <FontAwesomeIcon className="icon me-3" icon={faPencil} onClick={onClickEditUser} />
                <FontAwesomeIcon icon={faTrashCan} className="icon text-danger" onClick={onClickDeleteUser} />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">Username: {userData?.username}</div>
            </div>

            <div className="row mt-2">
              <div className="col-6">
                <label>Role: {userData?.role}</label>
              </div>
              {userData?.role === "employee" && (
                <div className="col-6">
                  <label>Floor : </label>

                  {userData?.floors?.map((user, index) => (
                    <label className="me-2">{user}</label> 

                  ))}
                </div>
              )}
            </div>

            {userData?.role === "employee" && (
              <div className="row mt-2">
                <div className="col-12">
                  <label>
                    Packager:{" "}
                    {userData?.packagerDetails?.length > 0
                      ? userData?.packagerDetails[0]?.firstname + " " + userData?.packagerDetails[0]?.lastname + " (" + userData?.packagerDetails[0]?.phone + ")"
                      : ""}
                  </label>
                </div>
              </div>
            )}

            <div className="row mt-2">
              <div className="col-12">
                <label>Pin: {userData?.pin}</label>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <label className="mb-1">Phone: {userData?.phone}</label>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <label>{userData?.details}</label>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function ListUsersComponent() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chatBotCreatorStore);

  const [selectedUserData, setSelectedUserData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getAllUsers = async () => {
    dispatch(getAllUsersThunk({ JWT: state.loginToken, currentPage, pageSize: 10 }));
  };

  const onClickDelete = () => {
    dispatch(deleteUserThunk({ userId: selectedUserData._id, JWT: state.loginToken }));
    setTimeout(() => {
      dispatch(getAllUsersThunk({ JWT: state.loginToken, currentPage, pageSize: 10 }));
    }, 500);
  };

  useEffect(() => {
    getAllUsers();
  }, [currentPage]);

  return (
    <>
      {state.showGetAllUsersLoader ? (
        <div className="centered" style={{ height: "300px" }}>
          <div className="centered" style={{ height: "300px" }}>
            <div className="spinner-border mx-2"></div>
            <span>Loading Users List...</span>
          </div>
        </div>
      ) : (
        <div className="col-12" style={{ height: "100%", overflowY: "scroll" }}>
          <div className="row">
            {state.getAllUsersErrorMessage === "" ? (
              state.userList?.length > 0 ? (
                state.userList?.map((userData, index) => (
                  <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12" key={index}>
                    <UserCard userData={userData} setSelectedUserData={setSelectedUserData} />
                  </div>
                ))
              ) : (
                <div>No Users Found. Please Add Users.</div>
              )
            ) : (
              <p className="text-danger centered">{state.getAllUsersErrorMessage}</p>
            )}
          </div>
          <div className="my-2 d-flex justify-content-end" style={{ position: "fixed", right: 10, bottom: 5 }}>
            <Pagination count={parseInt(state.totalUserCount / 10) + 1 > 10 ? 10 : parseInt(state.totalUserCount / 10) + 1} color="primary" page={currentPage} onChange={handlePageChange} />
          </div>
        </div>
      )}

      {/* ---------------------- Edit User Confirmation Modal ----------------------------*/}
      <Modal size="xl" show={state.showEditUserModal} onHide={() => dispatch(closeEditUserModal())}>
        <Modal.Header className="modal-bg">
          <Modal.Title className="px-2">Edit User</Modal.Title>
        </Modal.Header>
        <NewUserComponent userData={selectedUserData} />
      </Modal>

      {/* ---------------------- Edit User Confirmation Modal ----------------------------*/}

      {/* ---------------------- Delete User Confirmation Modal ----------------------------*/}
      <Modal size="lg" show={state.showDeleteUserModal} onHide={() => dispatch(closeDeleteUserModal())}>
        <Modal.Header className="modal-bg">
          <Modal.Title className="px-2">Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to continue?
          <br />
          {selectedUserData?.role === "packager" && (
            <b className="small-font">
              <label>Note: </label> Please assign new packager to employees (who were assigned to this packager) after deleting this packager.
            </b>
          )}
          <div className="d-flex justify-content-end mt-3">
            {state.showDeleteUserLoader ? (
              <Button className="me-2" variant="contained" color="error">
                Deleting...
              </Button>
            ) : (
              <Button className="me-3" variant="contained" color="error" onClick={onClickDelete}>
                Delete
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={() => dispatch(closeDeleteUserModal())}>
              Cancel
            </Button>
          </div>
          {state.deleteUserErrorMessage !== "" && <p className="text-danger centered mt-3">{state.deleteUserErrorMessage}</p>}
        </Modal.Body>
      </Modal>
      {/* ---------------------- Delete User Confirmation Modal ----------------------------*/}
    </>
  );
}

export default ListUsersComponent;
