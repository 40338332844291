import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewRackThunk } from '../store/chatBotCreatorThunk';
import Joi from 'joi';
import { Button, Card, CardContent, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

function NewRackComponent() {

    const dispatch = useDispatch();
    const state = useSelector(state => state.chatBotCreatorStore);

    const [rackNumber, setRackNumber] = useState("");
    const [floorNumber, setFloorNumber] = useState("");
    const [errors, setErrors] = useState([]);

    const onChangeFloor = (e) => {
        setFloorNumber(e.target.value);
        const index = errors.findIndex(ele => ele.path[0] === 'floorNumber');
        if (index >= 0) {
            errors.splice(index, 1);
            setErrors(errors);
        }
    }

    const onChangeRack = (e) => {
        setRackNumber(e.target.value);
        const index = errors.findIndex(ele => ele.path[0] === 'rackNumber');
        if (index >= 0) {
            errors.splice(index, 1);
            setErrors(errors);
        }
    }


    const onClickAddRack = (event) => {
        event.preventDefault();
        setErrors([]);

        const schema = Joi.object({
            floorNumber: Joi.string().required().messages({
                "string.empty": "Floor Number is required"
            }),
            rackNumber: Joi.string().required().messages({
                "string.empty": "Rack Number is required"
            }),
        });

        const { error } = schema.validate({ floorNumber, rackNumber }, { abortEarly: false });

        if (error) {
            setErrors(error.details);
            return;
        }


        dispatch(addNewRackThunk({ floorNumber, rackNumber, JWT: state.loginToken }));
        setFloorNumber("");
        setRackNumber("");
    }

    return (
        <Card
            className="card-border"
            fullWidth
        >
            <CardContent sx={{ padding: ".8rem !important" }} >

                <form className='form'>
                    <div className='row'>
                        <div className='col-6'>

                            <TextField
                                label={"Floor Number"}
                                onChange={onChangeFloor}
                                value={floorNumber}
                                size="small"
                                name="Floor Number"
                                fullWidth
                            />
                            <p className='text-danger'>{errors?.find(ele => ele.path[0] === "floorNumber")?.message}</p>
                        </div>
                        <div className='col-6'>
                            <TextField
                                label={"Rack Number"}
                                onChange={onChangeRack}
                                value={rackNumber}
                                size="small"
                                name="Rack Number"
                                fullWidth
                            />
                            <p className='text-danger'>{errors?.find(ele => ele.path[0] === "rackNumber")?.message}</p>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            {state.showAddRackLoader ?
                                <div>
                                    <div className="spinner-border mx-2" ></div>
                                    <span>Adding Rack...</span>
                                </div>
                                :

                                <Button
                                    variant="contained"
                                    size="medium"
                                    className="mt-1"
                                    startIcon={<SaveIcon />}
                                    onClick={onClickAddRack}
                                >
                                    Save Rack
                                </Button>
                            }
                        </div>
                    </div>
                    {state.errorAddRackMessage &&
                        <div className='row mt-1'>
                            <div className='col-12'>
                                <p className='text-danger'>{state.errorAddRackMessage}</p>
                            </div>
                        </div>
                    }
                </form >
            </CardContent>
        </Card >
    )
}

export default NewRackComponent;
